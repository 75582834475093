import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationsEN from "../../assets/locales/en.json";

const defaultLanguage = "en";

export const initializeIntl = () => {
  i18n.use(initReactI18next).init({
    fallbackLng: defaultLanguage,
    lng: defaultLanguage,
    resources: {
      en: {
        translation: translationsEN,
      },
    },
  });
};
