import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { getSalesBookings } from "./actions";

export type SalesBooking = {
  bookings: any;
};

export type SalesBookingsState = {
  isPending: boolean;
  salesBookings: SalesBooking[];
};

const initialState: SalesBookingsState = {
  isPending: true,
  salesBookings: [],
};

const salesBookingsSlice = createSlice({
  name: "salesBookings",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSalesBookings.pending, (state) => {
      state.isPending = true;
    });
    builder.addCase(getSalesBookings.rejected, (state) => {
      state.isPending = false;
    });
    builder.addCase(getSalesBookings.fulfilled, (state, action: PayloadAction<SalesBooking[]>) => {
      state.salesBookings = action.payload;
      state.isPending = false;
    });
  },
});

export default salesBookingsSlice;
