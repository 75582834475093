import axios from "axios";

import { store } from "../../store/store";
import { signOut } from "../../store/modules/auth/actions";

export const initializeInterceptors = () => {
  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    async function (error) {
      if (error.response.status === 401) {
        store.dispatch({ type: "CLEAR_DATA" });
        signOut();
      }

      return Promise.reject(error);
    },
  );
};
