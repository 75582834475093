import React, { memo } from "react";
import { PieChart  as Chart, Pie, Cell, ResponsiveContainer } from "recharts";

const data = [
  { name: "Group A", value: 400 },
  { name: "Group B", value: 400 },
];
const COLORS = ["#F4ADB1", "#F16335"];

type LineChartProps = {
    width?: number;
    height?: number;
    innerRadius?: number;
    outerRadius?: number;
    paddingAngle?: number;
};

export const PieChart = memo(({ width = 200, height= 100, innerRadius = 30, outerRadius = 45, paddingAngle = 0}: LineChartProps) => {

  return (
    <ResponsiveContainer width="100%" height={height}>
      <Chart width={width} height={height}>
        <Pie
          data={data}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          fill="#8884d8"
          paddingAngle={paddingAngle}
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </Chart>
    </ResponsiveContainer>
  );
});
