import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {getSalesCampspaces, getSalesEpic, getSalesPromoted, getVerified} from "./actions";

export type SalesCampspace = {
  bookings: any;
};

export type SalesListingsState = {
  isPending: boolean;
  salesCampspaces: SalesCampspace[];
};

const initialState: SalesListingsState = {
  isPending: true,
  salesCampspaces: [],
};

export type salesListingPromotedState = {
  isPending: boolean;
  isPromoted: boolean;
};

export type salesListingEpicState = {
  isPending: boolean;
  isEpic: boolean;
};

export type salesListingVerifiedState = {
  isPending: boolean;
  isVerified: boolean;
};

const initialPromotedState: salesListingPromotedState = {
  isPending: true,
  isPromoted: false,
};

const initialEpicState: salesListingEpicState = {
  isPending: true,
  isEpic: false,
};

const initialVerifiedState: salesListingVerifiedState = {
  isPending: true,
  isVerified: false,
};

const salesListingsSlice = createSlice({
  name: "salesListings",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSalesCampspaces.pending, (state) => {
      state.isPending = true;
    });
    builder.addCase(getSalesCampspaces.rejected, (state) => {
      state.isPending = false;
    });
    builder.addCase(getSalesCampspaces.fulfilled, (state, action: PayloadAction<SalesCampspace[]>) => {
      state.salesCampspaces = action.payload;
      state.isPending = false;
    });
  },
});

const salesListingsPromoted = createSlice({
  name: "salesListingsPromoted",
  initialState: initialPromotedState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSalesPromoted.pending, (state) => {
      state.isPending = true;
    });
    builder.addCase(getSalesPromoted.rejected, (state) => {
      state.isPending = false;
    });
    builder.addCase(getSalesPromoted.fulfilled, (state, action: PayloadAction<boolean>) => {
      state.isPromoted = action.payload;
      state.isPending = false;
    });
  },
});

const salesListingsEpic = createSlice({
  name: "salesListingsEpic",
  initialState: initialEpicState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSalesEpic.pending, (state) => {
      state.isPending = true;
    });
    builder.addCase(getSalesEpic.rejected, (state) => {
      state.isPending = false;
    });
    builder.addCase(getSalesEpic.fulfilled, (state, action: PayloadAction<boolean>) => {
      state.isEpic = action.payload;
      state.isPending = false;
    });
  },
});

const salesListingsVerified = createSlice({
  name: "salesListingsVerified",
  initialState: initialVerifiedState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getVerified.pending, (state) => {
      state.isPending = true;
    });
    builder.addCase(getVerified.rejected, (state) => {
      state.isPending = false;
    });
    builder.addCase(getVerified.fulfilled, (state, action: PayloadAction<boolean>) => {
      state.isVerified = action.payload;
      state.isPending = false;
    });
  },
});

export { salesListingsSlice, salesListingsPromoted, salesListingsEpic, salesListingsVerified };
