import React, { memo, ReactElement } from "react";

import { Header } from "../../components/header/header";

import { initializeInterceptors } from "../../utils/api/interceptors";

initializeInterceptors();

export const Wrapper = memo(({ children }: { children: ReactElement }) => (
  <>
    <Header />
    {children}
  </>
));
