import React, { ComponentType, memo } from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as ArrowRightDark } from "../../../assets/icons/ico_arrow_right_dark_dashboard.svg";
import { ReactComponent as ArrowRightWhite } from "../../../assets/icons/ico_arrow_right_white_dashboard.svg";
import { ReactComponent as FacebookDashboard } from "../../../assets/icons/ico_facebook_dashboard.svg";
import { ReactComponent as InstagramDashboard } from "../../../assets/icons/ico_instagram_dashboard.svg";
import dashboardInstagramDesktop from "../../../assets/images/dashboard_instagram_desktop.jpg";
import dashboardInstagramMobile from "../../../assets/images/dashboard_instagram_mobile.jpg";
import dashboardFacebookDesktop from "../../../assets/images/dashboard_facebook_desktop.jpg";
import dashboardFacebookMobile from "../../../assets/images/dashboard_facebook_mobile.jpg";
import {
  LinkItemIconWrapper,
  ItemImageIconText,
  DataCardSubheader,
  DataCardHeader,
  DataCardWrapper,
  LinkItemWrapper,
  LinksContainer,
  RoutingButton,
  ItemImageIcon,
  LinkItemText,
  RoutingHeader,
  LinksHeader,
  ItemImage,
  ItemDark,
  ItemLight,
  ItemEmpty,
  PendingSpinner,
} from "./styled";

import { businessLinksMap, externalLinksMap, salesLinksMap } from "../utils/configs";
import { useMediaQuery } from "../../../utils/hooks/use-media-query";

type OpenRoutingLink = (url: string | null, route: string | null) => void;

type DataItemProps = {
  header: string | null;
  subheader: string;
  icon: ComponentType;
  disabled?: boolean;
  isPending?: boolean;
};

export const SalesLinks = memo(({ openRoutingLink }: { openRoutingLink: OpenRoutingLink }) => {
  const { t } = useTranslation();

  return (
    <ItemDark sx={{ height: 510 }}>
      <LinksContainer>
        <RoutingHeader>{t("DASHBOARD.SALES")}</RoutingHeader>
        {salesLinksMap.map((link) => (
          <LinkItemWrapper
            key={link.label}
            onClick={() => openRoutingLink(link.url, link.route)}
            unselectable={link.disabled ? "on" : "off"}
          >
            <LinkItemIconWrapper>
              <ArrowRightWhite />
            </LinkItemIconWrapper>
            <RoutingButton label={t(link.label)} disabled={link.disabled} />
          </LinkItemWrapper>
        ))}
      </LinksContainer>
    </ItemDark>
  );
});

export const BusinessLinks = memo(({ openRoutingLink }: { openRoutingLink: OpenRoutingLink }) => {
  const { t } = useTranslation();

  return (
    <ItemDark sx={{ height: 245 }}>
      <LinksContainer>
        <RoutingHeader>{t("DASHBOARD.BUSINESS")}</RoutingHeader>
        {businessLinksMap.map((link) => (
          <LinkItemWrapper
            key={link.label}
            onClick={() => openRoutingLink(link.url, link.route)}
            unselectable={link.disabled ? "on" : "off"}
          >
            <LinkItemIconWrapper>
              <ArrowRightWhite />
            </LinkItemIconWrapper>
            <RoutingButton label={t(link.label)} disabled={link.disabled} />
          </LinkItemWrapper>
        ))}
      </LinksContainer>
    </ItemDark>
  );
});

export const ExternalLinks = memo(({ openExternalLink }: { openExternalLink: (url: string) => void }) => {
  const { t } = useTranslation();

  return (
    <ItemLight sx={{ height: 510 }}>
      <LinksContainer>
        <LinksHeader>{t("DASHBOARD.LINKS")}</LinksHeader>
        {externalLinksMap.map((link) => (
          <LinkItemWrapper key={link.label} onClick={() => openExternalLink(link.url)}>
            <LinkItemIconWrapper>
              <ArrowRightDark />
            </LinkItemIconWrapper>
            <LinkItemText>{t(link.label)}</LinkItemText>
          </LinkItemWrapper>
        ))}
      </LinksContainer>
    </ItemLight>
  );
});

export const FacebookItem = memo(({ onFacebookClickHandler }: { onFacebookClickHandler: () => void }) => {
  const { isDesktop } = useMediaQuery();
  const { t } = useTranslation();

  return (
    <ItemImage sx={{ height: 245 }} onClick={onFacebookClickHandler}>
      <ItemImageIcon>
        <FacebookDashboard />
        <ItemImageIconText>{t("DASHBOARD.FACEBOOK")}</ItemImageIconText>
      </ItemImageIcon>
      <img src={isDesktop ? dashboardFacebookDesktop : dashboardFacebookMobile} alt="facebook" loading="lazy" />
    </ItemImage>
  );
});

export const InstagramItem = memo(({ onInstagramClickHandler }: { onInstagramClickHandler: () => void }) => {
  const { isDesktop } = useMediaQuery();
  const { t } = useTranslation();

  return (
    <ItemImage sx={{ height: 245 }} onClick={onInstagramClickHandler}>
      <ItemImageIcon>
        <InstagramDashboard />
        <ItemImageIconText>{t("DASHBOARD.INSTAGRAM")}</ItemImageIconText>
      </ItemImageIcon>
      <img src={isDesktop ? dashboardInstagramDesktop : dashboardInstagramMobile} alt="instagram" loading="lazy" />
    </ItemImage>
  );
});

export const DataItem = memo(({ header, subheader, icon: Icon, disabled, isPending }: DataItemProps) => {
  return (
    <ItemLight sx={{ height: 245 }}>
      <DataCardWrapper>
        <Icon />
        {isPending || !header ? (
          <PendingSpinner />
        ) : (
          <DataCardHeader
            sx={(theme) => ({
              color: disabled ? theme.palette.text.disabled : theme.palette.primary.main,
            })}
          >
            {header}
          </DataCardHeader>
        )}
        <DataCardSubheader>{subheader}</DataCardSubheader>
      </DataCardWrapper>
    </ItemLight>
  );
});

export const GridPlaceholderItem = memo(() => <ItemEmpty sx={{ height: 245 }} />);
