import { createAsyncThunk } from "@reduxjs/toolkit";
import { getData } from "../../../utils/api/api";

export const getSalesCampspaces = createAsyncThunk("salesListings/getSalesCampspaces", async () => {
  return await getData("/campspaces");
});

export const getSalesPromoted = createAsyncThunk("salesListings/getSalesCampspacesPromoted", async (data: Record<string, unknown>) => {
  return await getData(`/campspaces/${data.camp_space_id}/promote/${data.value}`);
});

export const getSalesEpic = createAsyncThunk("salesListings/getSalesCampspacesEpic", async (data: Record<string, unknown>) => {
  return await getData(`/campspaces/${data.camp_space_id}/epic/${data.value}`);
});

export const getVerified = createAsyncThunk("salesListings/getSalesCampspacesVerified", async (data: Record<string, unknown>) => {
  return await getData(`/campspaces/${data.camp_space_id}/verified/${data.value}`);
});

