/* eslint-disable */

export const externalLinks = {
  feedbackForm: "https://docs.google.com/forms/d/e/1FAIpQLSfZF6fIgpklqsFU7aBCHrmzreY4rkE0gjgNz-LjtXY-gq51TQ/viewform",
  brandBook: "https://docs.google.com/presentation/d/1LE2l2k8dsZN7jDb_AM5h43i0COFSzLAfAGyKoWnjWyw/edit",
  hubspot: "https://www.hubspot.com",
  hotjar: "https://www.hotjar.com",
  sendgrid: "https://sendgrid.com",
  campanyon: "https://www.campanyon.com",
  campanyonDev: "https://dev-swack.campanyon.com",
  bonfire: "https://bonfire.campanyon.com",
  linkedIn: "https://www.linkedin.com/company/75425182",
  facebook: "https://www.facebook.com/Campanyon/",
  instagram: "https://www.instagram.com/campanyon/",
  zendesk: "https://campanyonsupport.zendesk.com/",
  scrapeRequest: "https://forms.gle/F5FNDFJfhwVxykyH9",
  salesDeck: "https://docs.google.com/presentation/d/1N_8SQAI9ibE4UM4vYUEtE4IhGa_sobBDGDfxlSNRo2k/edit#slide=id.ge6e56d2716_0_11",
  uniqueLocations: "https://docs.google.com/spreadsheets/d/1rDjuiH9LRbPYv_W86zoUVqOX6DCu2J9Olh79e5yL8go/edit#gid=1569217970",
  salesHandbook: "https://docs.google.com/document/d/1MdUbJ9X11GaErPNWHriamS9at9A6HVazvRWaSu7MIj4/edit",
  vacationTracker: "https://docs.google.com/spreadsheets/d/1X0LkfgliufX3e3IswPkeKDufASbJmSIMyg-AoUVZ29k/edit#gid=0",
  scoutOnboardingKit: "https://docs.google.com/presentation/d/1Hx5_SO3dGFcqjopF_nns7WFHy1CqFxwl7790AxbG3bo/edit#slide=id.gf01c0d712e_0_707",
  strategy2022: "https://docs.google.com/presentation/d/19hSN9Q70FsE7k45z3K6WBy2C686YXdzf2f10NULoC5s/edit#slide=id.g102af07578b_0_704",
};
