import React, { memo, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";

import { ReactComponent as CampanyonLogo } from "../../assets/images/logo-orange.svg";
import { ReactComponent as BurgerMenu } from "../../assets/icons/ico_burger.svg";
import { ReactComponent as CrossMenu } from "../../assets/icons/ico_cross.svg";
import {
  Sidebar,
  Toolbar,
  AppBar,
  HeaderContainer,
  MobileMenuWrapper,
  MobileCrossWrapper,
  ToolbarFlexWrapper,
  MobileLinksContainer,
  TabletHeaderContainer,
} from "./components/styled";
import { LinkButton } from "../button/link-button";

import { useDispatch } from "../../store/hooks";
import { signOut } from "../../store/modules/auth/actions";
import { useMediaQuery } from "../../utils/hooks/use-media-query";
import { externalLinks } from "../../utils/configs/external-links";

export const disabledRoutes = ["/signup"];

export const Header = memo(() => {
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);
  const { isMobile, isTablet, isDesktop } = useMediaQuery();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setSidebarOpen(false);
  }, [location.pathname]);

  const onLogoClickHandler = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onFeedbackFormsClickHandler = useCallback(() => {
    window.open(externalLinks.feedbackForm, "_blank");
  }, []);

  const onLogoutClickHandler = useCallback(() => {
    dispatch(signOut());
  }, [dispatch]);

  const onSidebarOpenHandler = useCallback(() => {
    setSidebarOpen((v) => !v);
  }, []);

  if (disabledRoutes.includes(location.pathname)) {
    return null;
  }

  return (
    <AppBar position="static">
      <HeaderContainer maxWidth="xl">
        <Toolbar disableGutters>
          <ToolbarFlexWrapper>
            <CampanyonLogo width={160} onClick={onLogoClickHandler} />
          </ToolbarFlexWrapper>
          <ToolbarFlexWrapper>
            {isMobile && (
              <>
                <MobileMenuWrapper>
                  <BurgerMenu onClick={onSidebarOpenHandler} />
                </MobileMenuWrapper>
                <Sidebar anchor="right" open={sidebarOpen} onClose={onSidebarOpenHandler}>
                  <MobileLinksContainer>
                    <MobileCrossWrapper>
                      <CrossMenu onClick={onSidebarOpenHandler} />
                    </MobileCrossWrapper>
                    <LinkButton label={t("HEADER_NAVIGATION.DASHBOARD")} route="/" />
                    <LinkButton label={t("HEADER_NAVIGATION.FEEDBACK_FORM")} onClick={onFeedbackFormsClickHandler} />
                    <LinkButton label={t("HEADER_NAVIGATION.SALES")} route="/sales" />
                    <LinkButton label={t("HEADER_NAVIGATION.BUSINESS")} route="/business" />
                    <LinkButton label={t("HEADER_NAVIGATION.LOGOUT")} onClick={onLogoutClickHandler} />
                  </MobileLinksContainer>
                </Sidebar>
              </>
            )}
            {isTablet && (
              <TabletHeaderContainer>
                <LinkButton label={t("HEADER_NAVIGATION.DASHBOARD")} route="/" />
                <LinkButton label={t("HEADER_NAVIGATION.FEEDBACK_FORM")} onClick={onFeedbackFormsClickHandler} />
                <MobileMenuWrapper sx={{ height: 16 }}>
                  <BurgerMenu onClick={onSidebarOpenHandler} />
                </MobileMenuWrapper>
                <Sidebar anchor="right" open={sidebarOpen} onClose={onSidebarOpenHandler}>
                  <MobileLinksContainer>
                    <MobileCrossWrapper>
                      <CrossMenu onClick={onSidebarOpenHandler} />
                    </MobileCrossWrapper>
                    <LinkButton label={t("HEADER_NAVIGATION.SALES")} route="/sales" />
                    <LinkButton label={t("HEADER_NAVIGATION.BUSINESS")} route="/business" />
                    <LinkButton label={t("HEADER_NAVIGATION.LOGOUT")} onClick={onLogoutClickHandler} />
                  </MobileLinksContainer>
                </Sidebar>
              </TabletHeaderContainer>
            )}
            {isDesktop && (
              <>
                <LinkButton label={t("HEADER_NAVIGATION.DASHBOARD")} route="/" />
                <LinkButton label={t("HEADER_NAVIGATION.FEEDBACK_FORM")} onClick={onFeedbackFormsClickHandler} />
                <LinkButton label={t("HEADER_NAVIGATION.SALES")} route="/sales" />
                <LinkButton label={t("HEADER_NAVIGATION.BUSINESS")} route="/business" />
                <LinkButton label={t("HEADER_NAVIGATION.LOGOUT")} onClick={onLogoutClickHandler} />
              </>
            )}
          </ToolbarFlexWrapper>
        </Toolbar>
      </HeaderContainer>
    </AppBar>
  );
});
