import React, { memo } from "react";
import { SxProps } from "@mui/system";
import { styled, Theme } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Masonry from "@mui/lab/Masonry";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import MuiButton from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

type LinkButtonBaseProps = {
  label: string;
  disabled?: boolean;
  onClick?: () => void;
};

export const ItemLight = styled(Paper)(({ theme }) => ({
  borderColor: theme.palette.secondary.main,
  borderWidth: "1px",
  borderStyle: "solid",
  boxShadow: "none",
}));

export const ItemDark = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.dark,
  display: "flex",
  boxShadow: "none",
}));

export const ItemImage = styled(Paper)(({ theme }) => ({
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  overflow: "hidden",
  position: "relative",
  boxShadow: "none",
  img: {
    height: 245,
    position: "absolute",
  },
  "&:hover": {
    boxShadow: theme.shadows[4],
  },
}));

export const ItemImageIcon = styled("div")({
  flexDirection: "column",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "100%",
  zIndex: 1,
});

export const ItemImageIconText = styled(Typography)(({ theme }) => ({
  fontSize: "22px",
  fontWeight: "600",
  letterSpacing: 0,
  lineHeight: "27px",
  textAlign: "center",
  marginTop: "8px",
  color: theme.palette.text.secondary,
}));

export const ItemEmpty = styled(Paper)({
  visibility: "hidden",
  position: "absolute",
});

export const CardsGrid = styled(Masonry)(({ theme }) => ({
  margin: 0,
  [theme.breakpoints.down("sm")]: {
    height: "unset",
    marginBottom: 24,
  },
}));

export const DashboardContainer = styled(Container)(({ theme }) => ({
  padding: "0 10px !important",
  marginTop: 54,
  [theme.breakpoints.between("md", "lg")]: {
    marginTop: 36,
  },
  [theme.breakpoints.down("md")]: {
    marginTop: 24,
  },
}));

export const LinksContainer = styled("div")({
  width: "100%",
  padding: "32px",
});

export const DataCardWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  height: "100%",
});

export const DataCardHeader = styled(Typography)(({ theme }) => ({
  fontSize: "30px",
  fontWeight: "500",
  letterSpacing: 0,
  lineHeight: "37px",
  marginTop: "26px",
  color: theme.palette.primary.main,
}));

export const DataCardSubheader = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  fontWeight: "500",
  letterSpacing: 0,
  lineHeight: "17px",
  marginTop: "4px",
  color: theme.palette.text.primary,
}));

export const LinksHeader = styled(Typography)(({ theme }) => ({
  fontSize: "22px",
  fontWeight: "500",
  letterSpacing: 0,
  lineHeight: "27px",
  marginBottom: "24px",
  color: theme.palette.text.primary,
}));

export const LinkItemWrapper = styled("div")(({ theme, unselectable }) => ({
  cursor: "pointer",
  flexDirection: "row",
  alignItems: "center",
  display: "flex",
  marginBottom: 24,
  ...(unselectable !== "on" && {
    g: {
      transition: "fill 0.25s ease-out",
    },
    "a, p, button": {
      transition: "color 0.25s ease-out",
    },
    "&:hover": {
      g: {
        transition: "fill 0.25s ease-out",
        fill: theme.palette.primary.main,
      },
      "a, p, button": {
        transition: "color 0.25s ease-out",
        color: theme.palette.primary.main,
      },
    },
  }),
  ...(unselectable === "on" && {
    cursor: "default",
    g: {
      fill: theme.palette.text.disabled,
    },
    "a, p, button": {
      color: `${theme.palette.text.disabled} !important`,
    },
  }),
}));

export const LinkItemIconWrapper = styled("div")({
  display: "flex",
  marginRight: "10px",
});

export const LinkItemText = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  fontWeight: "500",
  letterSpacing: 0,
  lineHeight: "17px",
  color: theme.palette.text.primary,
}));

export const RoutingHeader = styled(Typography)(({ theme }) => ({
  fontSize: "22px",
  fontWeight: "500",
  letterSpacing: 0,
  lineHeight: "27px",
  marginBottom: "24px",
  color: theme.palette.text.secondary,
}));

const styles: SxProps<Theme> = {
  color: (theme: Theme) => theme.palette.text.secondary,
  cursor: "pointer",
  fontSize: "14px",
  fontWeight: "600",
  lineHeight: "17px",
  letterSpacing: "0",
  padding: "0 !important",
  minWidth: "unset",
  textTransform: "none",
  transition: "none",
  span: {
    display: "none",
  },
  "&:hover": {
    backgroundColor: "transparent",
  },
};

export const RoutingButton = memo(({ label, disabled, onClick }: LinkButtonBaseProps) => {
  return (
    <MuiButton onClick={onClick} sx={styles} disabled={disabled}>
      {label}
    </MuiButton>
  );
});

export const PendingSpinner = styled(CircularProgress)({
  height: "27px !important",
  width: "27px !important",
  marginBottom: "10px",
  marginTop: "26px",
});
