import { styled } from "@mui/material/styles";
import { LoadingButton } from "@mui/lab";
import CircularProgress from "@mui/material/CircularProgress";

export const LoginButton = styled(LoadingButton)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontWeight: 700,
  minWidth: 320,
  boxShadow: "none",
  "&:hover": {
    boxShadow: "none",
  },
  "&:disabled": {
    backgroundColor: theme.palette.primary.main,
  },
  [theme.breakpoints.down(340)]: {
    minWidth: 280,
  },
}));

export const LogoWrapper = styled("div")({
  marginBottom: "56px",
});

export const PendingSpinner = styled(CircularProgress)(({ theme }) => ({
  color: theme.palette.text.secondary,
  height: "24px !important",
  width: "24px !important",
}));
