import React, {memo, useEffect, useMemo, useState} from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";

import { ReactComponent as PrivatelyOwned } from "../../../assets/icons/ico_hosts_dashboard.svg";
import { ReactComponent as Glamping } from "../../../assets/icons/ico_glamping_dashboard.svg";
import { ReactComponent as CampingSpots } from "../../../assets/icons/ico_listings_dashboard.svg";
import { ReactComponent as Accommodation } from "../../../assets/icons/ico_accommodation_dashboard.svg";

import { CardsGrid, FiltersColumn, FiltersContainer, SalesOverviewContainer } from "./components/styled";
import { ChartItem, DataItem } from "./components/data";
import { LineChart } from "../../../components/charts/lineChart";
import { Select } from "../../../components/select/select";

import { useDispatch, useSelector } from "../../../store/hooks";
import {
  getHostsByDay,
  getListingsAvailability,
  getListingsByDay,
  getListingsPublic
} from "../../../store/modules/sales-overview/actions";

const countriesOptions = [
  { value: 1, label: "All countries"},
  { value: 2, label: "Australia"},
  { value: 3, label: "Austria"},
  { value: 4, label: "Belgium"},
  { value: 5, label: "Canada"},
  { value: 6, label: "Denmark"},
  { value: 7, label: "France"},
  { value: 8, label: "Germany"},
  { value: 9, label: "Italy"},
  { value: 10, label: "Netherlands"},
  { value: 11, label: "Norway"},
  { value: 12, label: "Portugal"},
  { value: 13, label: "Spain"},
  { value: 14, label: "Sweden"},
  { value: 15, label: "Switzerland"},
  { value: 16, label: "UK"},
  { value: 17, label: "Other countries"}
];

const periodOptions = [
  { value: 1, label: "Today"},
  { value: 2, label: "Yesterday"},
  { value: 3, label: "This week (Mo - present)"},
  { value: 4, label: "Last week (Mo - Su)"},
  { value: 5, label: "This month"},
  { value: 6, label: "Last month"},
  { value: 7, label: "This year"},
  { value: 8, label: "Last year"}
];

const comparePeriodOptions = [
  { value: 1, label: "Do not compare"},
  { value: 2, label: "Compare with last period"},
  { value: 3, label: "Compare with same period last year"}
];

const exportOptions = [
  { value: 1, label: "Download CSV"},
  { value: 2, label: "Download PDF"}
];

export const SalesOverview = memo(() => {

  const { t } = useTranslation();
  const { hostsByDay } = useSelector((state) => state.salesHostsByDay);
  const { listingsByDay } = useSelector((state) => state.salesListingsByDay);
  const { listingsAvailability } = useSelector((state) => state.salesListingsAvailability);
  const { listingsPublic } = useSelector((state) => state.salesListingsPublic);

  const [periodValue, setPeriodValue] = useState("");
  const [compareValue, setCompareValue] = useState("");

  const dispatch = useDispatch();

  const graphsPeriods = useMemo(() => {

    const period = periodValue.toString();
    const compare = compareValue.toString();
    let dateFrom = "2022-01-18";
    let dateTo = "2022-01-24";
    let dateCompareFrom = "";
    let dateCompareTo = "";

    switch (period) {
    case "1": {
      dateFrom = moment().format("YYYY-MM-DD");
      dateTo = moment().format("YYYY-MM-DD");
      if (compare === "2") {
        dateCompareFrom = moment().subtract(1, "day").format("YYYY-MM-DD");
        dateCompareTo = moment().subtract(1, "day").format("YYYY-MM-DD");
      } else if (compare === "3") {
        dateCompareFrom = moment().subtract(1, "year").format("YYYY-MM-DD");
        dateCompareTo = moment().subtract(1, "year").format("YYYY-MM-DD");
      }
      break;
    }
    case "2": {
      dateFrom = moment().subtract(1, "day").format("YYYY-MM-DD");
      dateTo = moment().subtract(1, "day").format("YYYY-MM-DD");
      if (compare === "2") {
        dateCompareFrom = moment().subtract(2, "days").format("YYYY-MM-DD");
        dateCompareTo = moment().subtract(2, "days").format("YYYY-MM-DD");
      } else if (compare === "3") {
        dateCompareFrom = moment().subtract({day: 1, year: 1}).format("YYYY-MM-DD");
        dateCompareTo = moment().subtract({day: 1, year: 1}).format("YYYY-MM-DD");
      }
      break;
    }
    case "3": {
      dateFrom = moment().startOf("isoWeek").format("YYYY-MM-DD");
      dateTo = moment().format("YYYY-MM-DD");
      if (compare === "2") {
        dateCompareFrom = moment().subtract(1, "week").startOf("isoWeek").format("YYYY-MM-DD");
        dateCompareTo = moment().subtract(1, "week").startOf("isoWeek").add(6, "days").format("YYYY-MM-DD");
      } else if (compare === "3") {
        dateCompareFrom =  moment().startOf("isoWeek").subtract(1, "year").format("YYYY-MM-DD");
        dateCompareTo = moment().startOf("isoWeek").add(6, "days").subtract(1, "year").format("YYYY-MM-DD");
      }
      break;
    }
    case "4": {
      dateFrom = moment().subtract(1, "week").startOf("isoWeek").format("YYYY-MM-DD");
      dateTo = moment().subtract(1, "week").startOf("isoWeek").add(6, "days").format("YYYY-MM-DD");
      if (compare === "2") {
        dateCompareFrom = moment().subtract(2, "weeks").startOf("isoWeek").format("YYYY-MM-DD");
        dateCompareTo = moment().subtract(2, "weeks").startOf("isoWeek").add(6, "days").format("YYYY-MM-DD");
      } else if (compare === "3") {
        dateCompareFrom = moment().subtract(1, "week").startOf("isoWeek").subtract(1, "year").format("YYYY-MM-DD");
        dateCompareTo = moment().subtract(1, "week").startOf("isoWeek").add(6, "days").subtract(1, "year").format("YYYY-MM-DD");
      }
      break;
    }
    case "5": {
      dateFrom = moment().startOf("month").format("YYYY-MM-DD");
      dateTo = moment().format("YYYY-MM-DD");
      if (compare === "2") {
        dateCompareFrom = moment().subtract(1, "month").startOf("month").format("YYYY-MM-DD");
        dateCompareTo = moment().subtract(1, "month").endOf("month").format("YYYY-MM-DD");
      } else if (compare === "3") {
        dateCompareFrom = moment().subtract(1, "year").startOf("month").format("YYYY-MM-DD");
        dateCompareTo = moment().subtract(1, "year").endOf("month").format("YYYY-MM-DD");
      }
      break;
    }
    case "6": {
      dateFrom = moment().subtract(1, "month").startOf("month").format("YYYY-MM-DD");
      dateTo = moment().subtract(1, "month").endOf("month").format("YYYY-MM-DD");
      if (compare === "2") {
        dateCompareFrom = moment().subtract(2, "months").startOf("month").format("YYYY-MM-DD");
        dateCompareTo = moment().subtract(2, "months").endOf("month").format("YYYY-MM-DD");
      } else if (compare === "3") {
        dateCompareFrom = moment().subtract(1, "month").startOf("month").subtract(1, "year").format("YYYY-MM-DD");
        dateCompareTo = moment().subtract(1, "month").endOf("month").subtract(1, "year").format("YYYY-MM-DD");
      }
      break;
    }
    case "7": {
      dateFrom = moment().startOf("year").format("YYYY-MM-DD");
      dateTo = moment().format("YYYY-MM-DD");
      if (compare === "2" || compare === "3") {
        dateCompareFrom = moment().startOf("year").subtract(1, "year").format("YYYY-MM-DD");
        dateCompareTo = moment().endOf("year").subtract(1, "year").format("YYYY-MM-DD");
      }
      break;
    }
    case "8": {
      dateFrom = moment().subtract(1, "year").startOf("year").format("YYYY-MM-DD");
      dateTo = moment().subtract(1, "year").endOf("year").format("YYYY-MM-DD");
      if (compare === "2" || compare === "3") {
        dateCompareFrom = moment().startOf("year").subtract(2, "years").format("YYYY-MM-DD");
        dateCompareTo =  moment().endOf("year").subtract(2, "years").format("YYYY-MM-DD");
      }
      break;
    }
    default: {
      break;
    }
    }

    return {
      "date_from": dateFrom,
      "date_to": dateTo,
      "date_compare_from": dateCompareFrom,
      "date_compare_to": dateCompareTo,
      "type": "listings_public"
    };
  }, [periodValue, compareValue]);

  useEffect(() => {
    dispatch(getHostsByDay({...graphsPeriods, type: "hosts_byday"}));
    dispatch(getListingsByDay({...graphsPeriods, type: "listings_byday"}));
    dispatch(getListingsAvailability({...graphsPeriods, type: "listings_availability"}));
    dispatch(getListingsPublic(graphsPeriods));
  }, [dispatch, graphsPeriods]);

  const getDataGraphic = (timeseries: [], timeseries_compare: [] ) => {
    const currentData: any[]  = [];
    timeseries.map((item: any, idx: number) => {
      const { value } = timeseries_compare[idx] || {};
      currentData.push({
        label: moment(item.date).format("DD MMM."),
        current: Math.round(item.value),
        prev: Math.round(value),
      });
    });
    return currentData;
  };

  const headerHosts = useMemo(() => {
    return (hostsByDay && hostsByDay.total) || 0;
  }, [hostsByDay]);

  const changeHosts = useMemo(() => {
    return (hostsByDay && hostsByDay.change_percentage) || 0;
  }, [hostsByDay]);

  const dataHosts = useMemo(() => {
    return getDataGraphic(hostsByDay?.timeseries || [], hostsByDay?.timeseries_compare || []);
  }, [hostsByDay]);

  const headerListings = useMemo(() => {
    return (listingsByDay && listingsByDay.total) || 0;
  }, [listingsByDay]);

  const changeListings = useMemo(() => {
    return (listingsByDay && listingsByDay.change_percentage) || 0;
  }, [listingsByDay]);

  const dataListings = useMemo(() => {
    return getDataGraphic(listingsByDay?.timeseries || [], listingsByDay?.timeseries_compare || []);
  }, [listingsByDay]);

  const headerAvailability = useMemo(() => {
    return `${(listingsAvailability && listingsAvailability.total && Math.round(listingsAvailability.total)) || 0}%`;
  }, [listingsAvailability]);

  const changeAvailability = useMemo(() => {
    return (listingsAvailability && listingsAvailability.change_percentage) || 0;
  }, [listingsAvailability]);

  const dataAvailability = useMemo(() => {
    return getDataGraphic(listingsAvailability?.timeseries || [], listingsAvailability?.timeseries_compare || []);
  }, [listingsAvailability]);

  const headerPublicListing = useMemo(() => {
    return (listingsPublic && listingsPublic.total) || 0;
  }, [listingsPublic]);

  const changePublicListing = useMemo(() => {
    return (listingsPublic && listingsPublic.change_percentage) || 0;
  }, [listingsPublic]);

  const dataPublicListing = useMemo(() => {
    return getDataGraphic(listingsPublic?.timeseries || [], listingsPublic?.timeseries_compare || []);
  }, [listingsPublic]);

  const onChangePeriod = (e: any) => {
    setPeriodValue(e.target.value as string);
  };

  const onChangeCompare = (e: any) => {
    setCompareValue(e.target.value as string);
  };

  return (
    <SalesOverviewContainer>
      <FiltersContainer>
        <FiltersColumn>
          <Select options={countriesOptions} title={"Countries"} />
          <Select options={periodOptions} title={"Period"} customValue={periodValue} onChange={onChangePeriod}/>
        </FiltersColumn>
        <FiltersColumn>
          <Select options={comparePeriodOptions} title={"Compare"} customValue={compareValue} onChange={onChangeCompare} />
          <Select options={exportOptions} title={"Export"} />
        </FiltersColumn>
      </FiltersContainer>
      <CardsGrid container spacing={2}>
        <ChartItem headerValue={headerPublicListing} headerLabel={t("SALES.PUBLIC_LISTINGS")} change={changePublicListing}>
          <LineChart data={dataPublicListing} />
        </ChartItem>
        <ChartItem headerValue={headerAvailability} headerLabel={t("SALES.AVAILABILITY")} change={changeAvailability}>
          <LineChart data={dataAvailability} />
        </ChartItem>
      </CardsGrid>
      <CardsGrid container spacing={2}>
        <ChartItem headerValue={headerListings} headerLabel={t("SALES.PROPERTIES")} change={changeListings}>
          <LineChart data={dataListings} />
        </ChartItem>
        <ChartItem headerValue={headerHosts} headerLabel={t("SALES.HOSTS")} change={changeHosts}>
          <LineChart data={dataHosts} />
        </ChartItem>
      </CardsGrid>
      <CardsGrid container spacing={2}>
        <DataItem header={"40%"} change={-0.2} subheader={t("SALES.PRIVATELY_OWNED")} icon={PrivatelyOwned} />
        <DataItem header={"30%"} change={-0.2} subheader={t("SALES.GLAMPING")} icon={Glamping} />
        <DataItem header={"20%"} change={0.2} subheader={t("SALES.CAMPING_SPOTS")} icon={CampingSpots} />
        <DataItem header={"40%"} change={-0.2} subheader={t("SALES.PROVIDED_ACCOMMODATION")} icon={Accommodation} />
      </CardsGrid>
    </SalesOverviewContainer>
  );
});
