import { createAsyncThunk } from "@reduxjs/toolkit";
import { postData } from "../../../utils/api/api";

export const getHostsByDay = createAsyncThunk("salesOverview/getHostsByDay", async (data: Record<string, unknown>) => {
  return await postData("/statistics/graph", data);
});

export const getListingsByDay = createAsyncThunk("salesOverview/getListingsByDay", async (data: Record<string, unknown>) => {
  return await postData("/statistics/graph", data);
});

export const getListingsAvailability = createAsyncThunk("salesOverview/getListingsAvailability", async (data: Record<string, unknown>) => {
  return await postData("/statistics/graph", data);
});

export const getListingsPublic = createAsyncThunk("salesOverview/getListingsPublic", async (data: Record<string, unknown>) => {
  return await postData("/statistics/graph", data);
});
