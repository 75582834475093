import React, { memo } from "react";
import { NavLink } from "react-router-dom";
import { Theme } from "@mui/material/styles";
import { SxProps } from "@mui/system";
import MuiButton from "@mui/material/Button";

type LinkButtonBaseProps = {
  label: string;
  route?: string;
  disabled?: boolean;
  onClick?: () => void;
};

const styles: SxProps<Theme> = (theme) => ({
  color: theme.palette.text.primary,
  cursor: "pointer",
  fontSize: "14px",
  fontWeight: "600",
  lineHeight: "17px",
  letterSpacing: "0.19px",
  textTransform: "uppercase",
  span: {
    display: "none",
  },
  "&:hover": {
    backgroundColor: "transparent",
    color: theme.palette.primary.dark,
  },
  "&.active": {
    color: theme.palette.primary.main,
  },
  [theme.breakpoints.down("lg")]: {
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "20px",
    letterSpacing: 0,
  },
});

export const LinkButton = memo(({ route, label, disabled, onClick }: LinkButtonBaseProps) => {
  if (onClick) {
    return (
      <MuiButton onClick={onClick} sx={styles} disabled={disabled}>
        {label}
      </MuiButton>
    );
  }

  if (route) {
    return (
      <MuiButton component={NavLink} to={route} sx={styles} disabled={disabled}>
        {label}
      </MuiButton>
    );
  }

  return null;
});
