import useQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

export const useMediaQuery = () => {
  const theme = useTheme();

  const isDesktop = useQuery(theme.breakpoints.up("lg"));
  const isMobile = useQuery(theme.breakpoints.down("md"));
  const isTablet = useQuery(theme.breakpoints.between("md", "lg"));

  return {
    isTablet,
    isMobile,
    isDesktop,
  };
};
