import { externalLinks } from "../../../utils/configs/external-links";

export const externalLinksMap = [
  {
    url: externalLinks.uniqueLocations,
    label: "DASHBOARD.UNIQUE_LOCATIONS",
  },
  {
    url: externalLinks.scrapeRequest,
    label: "DASHBOARD.SCRAPE_REQUEST",
  },
  {
    url: externalLinks.zendesk,
    label: "DASHBOARD.ZENDESK",
  },
  {
    url: externalLinks.salesHandbook,
    label: "DASHBOARD.SALES_HANDBOOK",
  },
  {
    url: externalLinks.scoutOnboardingKit,
    label: "DASHBOARD.SCOUT_ONBOARDING_KIT",
  },
  {
    url: externalLinks.salesDeck,
    label: "DASHBOARD.SALES_DECK",
  },
];
