import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { getStatistics } from "./actions";

export type StatisticsData = {
  bookings: number;
  hosts: number;
  listings: number;
  satisfaction: number;
};

export type DashboardState = {
  isPending: boolean;
  statistics: StatisticsData | null;
};

const initialState: DashboardState = {
  isPending: true,
  statistics: null,
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getStatistics.pending, (state) => {
      state.isPending = true;
    });
    builder.addCase(getStatistics.rejected, (state) => {
      state.isPending = false;
    });
    builder.addCase(getStatistics.fulfilled, (state, action: PayloadAction<StatisticsData>) => {
      state.statistics = action.payload;
      state.isPending = false
    });
  },
});

export default dashboardSlice;
