import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { signInWithGoogle, signOut } from "./actions";

export type AuthState = {
  isPending: boolean;
  authToken: string | null;
  error: string | null;
};

const initialState: AuthState = {
  isPending: false,
  authToken: null,
  error: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    resetSignupInit(state) {
      state.error = null
      state.isPending = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signInWithGoogle.pending, (state) => {
      state.isPending = true;
    });
    builder.addCase(signInWithGoogle.rejected, (state) => {
      state.isPending = false;
    });
    builder.addCase(
      signInWithGoogle.fulfilled,
      (state, action: PayloadAction<{ authToken: string | null; error: string | null }>) => {
        state.authToken = action.payload.authToken;
        state.error = action.payload.error;
        state.isPending = false;
      },
    );
    builder.addCase(signOut.fulfilled, (state) => {
      state.authToken = null;
    });
  },
});

export const { resetSignupInit } = authSlice.actions;

export default authSlice;
