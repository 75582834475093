// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { memo, useEffect, useMemo, useState } from "react";

import {
  SalesBookingsContainer,
  PendingSpinnerContainer,
  PendingSpinner,
  SalesListContainer,
  DataGridContainer,
  GridToolbarContainerExport,
  GridToolbarExport,
  MuiTooltip,
} from "./components/styled";

import { useDispatch, useSelector } from "../../../store/hooks";
import { getSalesBookings } from "../../../store/modules/sales-bookings/actions";

const status = [
  "Requested",
  "Confirmed",
  "PendingPayment",
  "Paid",
  "Completed",
  "Declined",
  "Cancelled",
  "Payout",
];

const CustomToolbar = () => {
  return (
    <GridToolbarContainerExport>
      <GridToolbarExport printOptions={{}}/>
    </GridToolbarContainerExport>
  );
};

const cols = [
  { field: "id", headerName: "ID", width: 20, renderCell: (params: any) => (
      <MuiTooltip title={params.row.id} >
        <div className="MuiDataGrid-cellContent">{params.row.id}</div>
      </MuiTooltip>
    ), 
  },
  { field: "createdAt", headerName: "Booking", type: "date", flex: 0.3, renderCell: (params: any) => (
      <MuiTooltip title={params.row.createdAt} >
        <div className="MuiDataGrid-cellContent">{params.row.createdAt}</div>
      </MuiTooltip>
    ), 
  },
  { field: "arrivalAt", headerName: "Arrival", type: "date", flex: 0.3, renderCell: (params: any) => (
      <MuiTooltip title={params.row.arrivalAt} >
        <div className="MuiDataGrid-cellContent">{params.row.arrivalAt}</div>
      </MuiTooltip>
    ), 
  },
  { field: "status", headerName: "Status", renderCell: (params: any) => (
      <MuiTooltip title={params.row.status} >
        <div className="MuiDataGrid-cellContent">{params.row.status}</div>
      </MuiTooltip>
    ), 
  },
  { field: "host", headerName: "Host", renderCell: (params: any) => (
      <MuiTooltip title={params.row.host} >
        <div className="MuiDataGrid-cellContent">{params.row.host}</div>
      </MuiTooltip>
    ), 
  },
  { field: "country", headerName: "Listing country", width: 20, renderCell: (params: any) => (
      <MuiTooltip title={params.row.country} >
        <div className="MuiDataGrid-cellContent">{params.row.country}</div>
      </MuiTooltip>
    ), 
  },
  { field: "country_guest", headerName: "Camper country", width: 20, renderCell: (params: any) => (
      <MuiTooltip title={params.row.country_guest} >
        <div className="MuiDataGrid-cellContent">{params.row.country_guest}</div>
      </MuiTooltip>
    ), 
  },
  { field: "guest", headerName: "Guest", renderCell: (params: any) => (
      <MuiTooltip title={params.row.guest} >
        <div className="MuiDataGrid-cellContent">{params.row.guest}</div>
      </MuiTooltip>
    ), 
  },
  { field: "numberOfGuests", headerName: "# Guests", width: 20, renderCell: (params: any) => (
      <MuiTooltip title={params.row.numberOfGuests} >
        <div className="MuiDataGrid-cellContent">{params.row.numberOfGuests}</div>
      </MuiTooltip>
    ), 
  },
  { field: "numberOfNights", headerName: "# Nights", width: 20, renderCell: (params: any) => (
      <MuiTooltip title={params.row.numberOfNights} >
        <div className="MuiDataGrid-cellContent">{params.row.numberOfNights}</div>
      </MuiTooltip>
    ), 
  },
  { field: "billedPrice", headerName: "$", renderCell: (params: any) => (
      <MuiTooltip title={params.row.billedPrice} >
        <div className="MuiDataGrid-cellContent">{params.row.billedPrice}</div>
      </MuiTooltip>
    ), 
  },
  { field: "campspace", headerName: "Campspace2", flex: 1, renderCell: (params: any) => (
      <MuiTooltip title={params.row.campspace} >
        <div className="MuiDataGrid-cellContent">{params.row.campspace}</div>
      </MuiTooltip>
    ), 
  },
  { field: "property_name", headerName: "Property Name", flex: 1, minWidth: 60, renderCell: (params: any) => (
      <MuiTooltip title={params.row.property_name} >
        <div className="MuiDataGrid-cellContent">{params.row.property_name}</div>
      </MuiTooltip>
    ), 
  },
  { field: "property_category", headerName: "Category", flex: 1, minWidth: 60, renderCell: (params: any) => (
      <MuiTooltip title={params.row.property_category} >
        <div className="MuiDataGrid-cellContent">{params.row.property_category}</div>
      </MuiTooltip>
    ), 
  },
  { field: "property_subcategory", headerName: "Subcategory", flex: 1, minWidth: 60, renderCell: (params: any) => (
      <MuiTooltip title={params.row.property_subcategory} >
        <div className="MuiDataGrid-cellContent">{params.row.property_subcategory}</div>
      </MuiTooltip>
    ), 
  },
];

export const SalesBookings = memo(() => {
  const [initialized, setInitialized] = useState<boolean>(false);

  const { salesBookings, isPending } = useSelector((state) => state.salesBookings);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSalesBookings());
    setInitialized(true);
  }, [dispatch]);

  const sortedSalesBookings = useMemo(() => {
    if (salesBookings.length) {
      return salesBookings.slice().sort((a, b) => {
        if (a.created_at < b.created_at) {
          return -1;
        }
        return 1;
      });
    }

    return [];
  }, [salesBookings]);

  const rows = useMemo(() => {
    if (sortedSalesBookings.length) {
      return sortedSalesBookings.map((item) => {
        const { departure_at, arrival_at } = item;

        const diff = (new Date(departure_at).getTime() - new Date(arrival_at).getTime()) / 24 / 60 / 60 / 1000;

        return {
          id: item.id,
          createdAt: item.created_at && item.created_at.substring(0, 10),
          arrivalAt: item.arrival_at && item.arrival_at.substring(0, 10),
          status: status[item.status],
          country: item.country,
          country_guest: item.country_guest,
          host: item.email_host,
          guest: item.email_guest,
          numberOfGuests: item.guests,
          numberOfNights: diff,
          billedPrice: item.billed_price + " " + item.billed_currency,
          campspace: item.campspace_name,
          property_name: item.campsite_name,
          property_category: item.campsite_type || "",
          property_subcategory: (item.campspace_type  || "").replace(/_/g, " "),
        };
      });
    }

    return [];
  }, [sortedSalesBookings]);

  return (
    <SalesBookingsContainer>
      {isPending || !initialized ? (
        <PendingSpinnerContainer>
          <PendingSpinner />
        </PendingSpinnerContainer>
      ) : (
        <SalesListContainer>
          <DataGridContainer autoHeight rows={rows} columns={cols} components={{ Toolbar: CustomToolbar }}  />
        </SalesListContainer>
      )}
    </SalesBookingsContainer>
  );
});
