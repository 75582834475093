import React, { ComponentType, memo, ReactElement, useMemo } from "react";

import { ReactComponent as ArrowDown } from "../../../../assets/icons/ico_arrow_down.svg";
import { ReactComponent as ArrowUp } from "../../../../assets/icons/ico_arrow_up.svg";

import {
  GridItem,
  ItemLight,
  DataCardHeader,
  DataCardWrapper,
  DataCardSubheader,
  PendingSpinner,
  DataCardChange,
  ChartItemLight,
  ChartItemHeaderValue,
  ChartHeaderContainer,
  ChartItemHeaderLabel,
  ChartItemDataChange,
} from "./styled";

type DataItemProps = {
  header: string | null;
  subheader: string;
  change: number;
  icon: ComponentType;
  disabled?: boolean;
  isPending?: boolean;
};

export const DataItem = memo(({ header, subheader, change, disabled, isPending, icon: Icon }: DataItemProps) => {
  const percentageChange = useMemo(() => {
    return `${Math.abs(change * 100)}%`;
  }, [change]);

  return (
    <GridItem item xs={12} sm={12} md={6} lg={3} xl={3}>
      <ItemLight sx={{ height: 300 }}>
        <DataCardWrapper>
          <Icon />
          {isPending || !header ? (
            <PendingSpinner />
          ) : (
            <DataCardHeader
              sx={(theme) => ({
                color: disabled ? theme.palette.text.disabled : theme.palette.primary.main,
              })}
            >
              {header}
            </DataCardHeader>
          )}
          <DataCardSubheader>{subheader}</DataCardSubheader>
          <DataCardChange
            sx={(theme) => ({ color: change >= 0 ? theme.palette.success.light : theme.palette.error.light })}
          >
            {change >= 0 ? <ArrowUp style={{ marginRight: 4 }} /> : <ArrowDown style={{ marginRight: 4 }} />}
            {percentageChange}
          </DataCardChange>
        </DataCardWrapper>
      </ItemLight>
    </GridItem>
  );
});

type ChartItemProps = {
  children: ReactElement;
  headerValue: string | number;
  headerLabel: string;
  change: number;
};

export const ChartItem = memo(({ children, headerValue, headerLabel, change }: ChartItemProps) => {
  const percentageChange = useMemo(() => {
    return `${Math.abs(change * 100)}%`;
  }, [change]);

  return (
    <GridItem item xs={12} sm={12} md={12} lg={6} xl={6}>
      <ChartItemLight>
        <ChartHeaderContainer>
          <ChartItemHeaderValue>{headerValue}</ChartItemHeaderValue>
          <ChartItemHeaderLabel>{headerLabel}</ChartItemHeaderLabel>
          <ChartItemDataChange
            sx={(theme) => ({ color: change >= 0 ? theme.palette.success.light : theme.palette.error.light })}
          >
            {change >= 0 ? <ArrowUp style={{ marginRight: 4 }} /> : <ArrowDown style={{ marginRight: 4 }} />}
            {percentageChange}
          </ChartItemDataChange>
        </ChartHeaderContainer>
        {children}
      </ChartItemLight>
    </GridItem>
  );
});
