import { createAsyncThunk } from "@reduxjs/toolkit";
import { GoogleAuthProvider, getAuth, signInWithPopup } from "firebase/auth";
import { getData } from "../../../utils/api/api";

const TENANT_ID = String(process.env.REACT_APP_FIREBASE_TENANT_ID);

export const signInWithGoogle = createAsyncThunk("auth/signInWithGoogle", async () => {
  const provider = new GoogleAuthProvider();
  const auth = getAuth();

  auth.tenantId = TENANT_ID;
  auth.useDeviceLanguage();

  const result = await signInWithPopup(auth, provider);
  const authToken = await result.user.getIdToken();
  const response = await getData("/statistics");

  if (!response || response.status === 401) {
    return {
      error: "MESSAGES.ERROR_401",
      authToken: null,
    };
  }

  return {
    error: null,
    authToken,
  };
});

export const signOut = createAsyncThunk("auth/signOut", async () => {
  const auth = getAuth();

  await auth.signOut();
});
