import React, { memo, useCallback, useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { MobileCrossWrapper, MobileLinksContainer, Sidebar } from "../../components/header/components/styled";
import { ReactComponent as CrossMenu } from "../../assets/icons/ico_cross.svg";
import {
  BusinessLinksContainerTablet,
  BusinessHeaderContainer,
  BusinessLinksContainer,
  BusinessContainer,
  BusinessLinksRow,
  BusinessHeader,
  ExternalLink,
  LinkTab,
  Tabs,
} from "./components/styled";

import { useMediaQuery } from "../../utils/hooks/use-media-query";
import { windowLocationCheck } from "./utils/window-location-check";
import { businessLocations, locations } from "./utils/locations";
import { externalLinksMap } from "./utils/configs";

export const Business = memo(() => {
  const [currentRouteIndex, setCurrentRouteIndex] = useState(windowLocationCheck());
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);

  const location = useLocation();
  const { t } = useTranslation();
  const { isMobile, isTablet, isDesktop } = useMediaQuery();

  useEffect(() => {
    const index = businessLocations.indexOf(location.pathname);

    setCurrentRouteIndex(index);
  }, [location]);

  const onSidebarOpenHandler = useCallback(() => {
    setSidebarOpen((v) => !v);
  }, []);

  return (
    <BusinessContainer maxWidth="xl">
      <BusinessHeaderContainer>
        <BusinessHeader>{t("BUSINESS.HEADER")}</BusinessHeader>
        {isDesktop && (
          <BusinessLinksContainer>
            {externalLinksMap.map((link) => (
              <ExternalLink href={link.url} target="_blank" key={link.url}>
                {t(link.label)}
              </ExternalLink>
            ))}
          </BusinessLinksContainer>
        )}
        {isTablet && (
          <BusinessLinksContainerTablet>
            <BusinessLinksRow>
              {externalLinksMap.slice(0, 3).map((link) => (
                <ExternalLink href={link.url} target="_blank" key={link.url}>
                  {t(link.label)}
                </ExternalLink>
              ))}
            </BusinessLinksRow>
            <BusinessLinksRow>
              {externalLinksMap.slice(3).map((link) => (
                <ExternalLink href={link.url} target="_blank" key={link.url}>
                  {t(link.label)}
                </ExternalLink>
              ))}
            </BusinessLinksRow>
          </BusinessLinksContainerTablet>
        )}
        {isMobile && (
          <>
            <ExternalLink onClick={onSidebarOpenHandler}>{t("SALES.LINKS")}</ExternalLink>
            <Sidebar anchor="right" open={sidebarOpen} onClose={onSidebarOpenHandler}>
              <MobileLinksContainer>
                <MobileCrossWrapper>
                  <CrossMenu onClick={onSidebarOpenHandler} />
                </MobileCrossWrapper>
                {externalLinksMap.map((link) => (
                  <ExternalLink href={link.url} target="_blank" key={link.url}>
                    {t(link.label)}
                  </ExternalLink>
                ))}
              </MobileLinksContainer>
            </Sidebar>
          </>
        )}
      </BusinessHeaderContainer>
      <Tabs value={currentRouteIndex}>
        <LinkTab label={t("BUSINESS.OVERVIEW")} href={locations.businessOverview} active={currentRouteIndex === 0} />
      </Tabs>
      <Outlet />
    </BusinessContainer>
  );
});
