import React, { memo, useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { ReactComponent as Bookings } from "../../assets/icons/ico_bookings_dashboard.svg";
import { ReactComponent as CustomerSatisfaction } from "../../assets/icons/ico_customer_satisfaction_dashboard.svg";
import { ReactComponent as Listings } from "../../assets/icons/ico_listings_dashboard.svg";
import { ReactComponent as Hosts } from "../../assets/icons/ico_hosts_dashboard.svg";
import { DashboardContainer, CardsGrid } from "./components/styled";
import {
  GridPlaceholderItem,
  ExternalLinks,
  BusinessLinks,
  InstagramItem,
  FacebookItem,
  SalesLinks,
  DataItem,
} from "./components/grid";

import { targets } from "../../utils/configs/targets";
import { useDispatch, useSelector } from "../../store/hooks";
import { externalLinks } from "../../utils/configs/external-links";
import { useMediaQuery } from "../../utils/hooks/use-media-query";
import { getStatistics } from "../../store/modules/dashboard/actions";
import { numberToLocale } from "./utils/number-to-locale";

export const Dashboard = memo(() => {
  const { statistics, isPending } = useSelector((state) => state.dashboard);
  const { isMobile, isTablet, isDesktop } = useMediaQuery();
  const { t } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getStatistics());
  }, [dispatch]);

  const onInstagramClickHandler = useCallback(() => {
    window.open(externalLinks.instagram, "_blank");
  }, []);

  const onFacebookClickHandler = useCallback(() => {
    window.open(externalLinks.facebook, "_blank");
  }, []);

  const openExternalLink = useCallback((link) => {
    window.open(link, "_blank");
  }, []);

  const openRoutingLink = useCallback(
    (link: string | null, route: string | null) => {
      if (link) {
        window.open(link, "_blank");
      } else if (route) {
        navigate(route);
      }
    },
    [navigate],
  );

  const publicListingsHeader = useMemo(() => {
    if (statistics && statistics.listings) {
      return `${numberToLocale(statistics.listings)}/${numberToLocale(targets.publicListings)}`;
    }

    return null;
  }, [statistics]);

  const hostsHeader = useMemo(() => {
    if (statistics && statistics.hosts) {
      return `${numberToLocale(statistics.hosts)}/${numberToLocale(targets.hosts)}`;
    }

    return null;
  }, [statistics]);

  const bookingsHeader = useMemo(() => {
    if (statistics && statistics.bookings) {
      return `${numberToLocale(statistics.bookings)}/${numberToLocale(targets.bookings)}`;
    }

    return null;
  }, [statistics]);

  const customerSatisfactionHeader = useMemo(() => {
    if (statistics && statistics.satisfaction) {
      return `${numberToLocale(statistics.satisfaction)}/${numberToLocale(targets.satisfaction, 1)}`;
    }

    return null;
  }, [statistics]);

  return (
    <DashboardContainer maxWidth="xl">
      <CardsGrid columns={{ xs: 1, sm: 2, md: 2, lg: 4, xl: 4 }} spacing={2}>
        {isDesktop && (
          <>
            <DataItem
              header={publicListingsHeader}
              subheader={t("DASHBOARD.PUBLIC_LISTINGS")}
              icon={Listings}
              isPending={isPending}
            />
            <DataItem header={hostsHeader} subheader={t("DASHBOARD.HOSTS")} icon={Hosts} isPending={isPending} />
            <SalesLinks openRoutingLink={openRoutingLink} />
            <BusinessLinks openRoutingLink={openRoutingLink} />
            <DataItem
              header={bookingsHeader}
              subheader={t("DASHBOARD.BOOKINGS")}
              icon={Bookings}
              isPending={isPending}
            />
            <DataItem
              header={customerSatisfactionHeader}
              subheader={t("DASHBOARD.CUSTOMER_SATISFACTION")}
              icon={CustomerSatisfaction}
              isPending={isPending}
              disabled
            />
            <ExternalLinks openExternalLink={openExternalLink} />
            <GridPlaceholderItem />
            <FacebookItem onFacebookClickHandler={onFacebookClickHandler} />
            <InstagramItem onInstagramClickHandler={onInstagramClickHandler} />
          </>
        )}
        {isTablet && (
          <>
            <DataItem
              header={publicListingsHeader}
              subheader={t("DASHBOARD.PUBLIC_LISTINGS")}
              icon={Listings}
              isPending={isPending}
            />
            <DataItem header={hostsHeader} subheader={t("DASHBOARD.HOSTS")} icon={Hosts} isPending={isPending} />
            <DataItem
              header={bookingsHeader}
              subheader={t("DASHBOARD.BOOKINGS")}
              icon={Bookings}
              isPending={isPending}
            />
            <DataItem
              header={customerSatisfactionHeader}
              subheader={t("DASHBOARD.CUSTOMER_SATISFACTION")}
              icon={CustomerSatisfaction}
              isPending={isPending}
              disabled
            />
            <SalesLinks openRoutingLink={openRoutingLink} />
            <BusinessLinks openRoutingLink={openRoutingLink} />
            <ExternalLinks openExternalLink={openExternalLink} />
            <InstagramItem onInstagramClickHandler={onInstagramClickHandler} />
            <FacebookItem onFacebookClickHandler={onFacebookClickHandler} />
            <GridPlaceholderItem />
          </>
        )}
        {isMobile && (
          <>
            <DataItem
              header={publicListingsHeader}
              subheader={t("DASHBOARD.PUBLIC_LISTINGS")}
              icon={Listings}
              isPending={isPending}
            />
            <DataItem header={hostsHeader} subheader={t("DASHBOARD.HOSTS")} icon={Hosts} isPending={isPending} />
            <DataItem
              header={bookingsHeader}
              subheader={t("DASHBOARD.BOOKINGS")}
              icon={Bookings}
              isPending={isPending}
            />
            <DataItem
              header={customerSatisfactionHeader}
              subheader={t("DASHBOARD.CUSTOMER_SATISFACTION")}
              icon={CustomerSatisfaction}
              isPending={isPending}
              disabled
            />
            <SalesLinks openRoutingLink={openRoutingLink} />
            <BusinessLinks openRoutingLink={openRoutingLink} />
            <ExternalLinks openExternalLink={openExternalLink} />
            <InstagramItem onInstagramClickHandler={onInstagramClickHandler} />
            <FacebookItem onFacebookClickHandler={onFacebookClickHandler} />
            <GridPlaceholderItem />
          </>
        )}
      </CardsGrid>
    </DashboardContainer>
  );
});
