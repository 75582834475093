import React, { memo } from "react";
import { SxProps } from "@mui/system";
import { Theme } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import MuiSelect, { SelectChangeEvent } from "@mui/material/Select";
import { ReactComponent as SelectArrowDown } from "../../assets/icons/ico_arrow_down_black.svg";

const selectStyles: SxProps<Theme> = (theme) => ({
  borderRadius: 0,
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#D9D9D9",
  },
  "& .MuiSelect-select": {
    padding: "10.5px 16px",
    paddingRight: "44px !important",
  },
  "& .MuiSelect-icon": {
    top: "unset",
    right: "22px",
  },
  "& .MuiSelect-iconOpen": {
    transform: "none",
  },
  "legend > span": {
    display: "none",
  },
});

const labelStyles: SxProps<Theme> = (theme) => ({
  color: theme.palette.secondary.dark,
  fontSize: "16px",
  letterSpacing: 0,
  lineHeight: "22px",
  top: 2,
  left: 2,
  "&.Mui-focused": {
    transform: "translate(0px, -20px) scale(0.75)",
  },
  "&.MuiFormLabel-filled": {
    transform: "translate(0px, -20px) scale(0.75)",
  },
});

type SelectProps = {
  options?: { value: string | number; label: string; }[];
  title?: string;
  labelId?: string;
  label?: string;
  customValue?: string | number
  onChange?: any;
};

const defaultOptions = [
  { value: 10, label: "Ten"},
  { value: 20, label: "Twenty"},
  { value: 30, label: "Thirty"}
];

const defaultPlaceholder = "Select with options";
const defaultLabelId = "select_id";
const defaultLabel = "Select with options";

export const Select = memo(({ options = defaultOptions, title = defaultPlaceholder, labelId = defaultLabelId,
  label = defaultLabel, customValue, onChange } : SelectProps) => {

  const [value, setValue] = React.useState("");

  const handleChange = (event: SelectChangeEvent) => {
    setValue(event.target.value as string);
  };

  return (
    <FormControl size="small" sx={{ minWidth: 245, borderRadius: 0 }}>
      <InputLabel id={labelId} sx={labelStyles}>
        { title }
      </InputLabel>
      <MuiSelect
        labelId={labelId}
        label={label}
        value={customValue || value}
        onChange={onChange || handleChange}
        MenuProps={{
          disableScrollLock: true,
          autoFocus: false,
        }}
        sx={selectStyles}
        IconComponent={SelectArrowDown}
      >
        {
          options.map((item, idx) => <MenuItem key={idx} value={item.value}>{item.label}</MenuItem> )
        }
      </MuiSelect>
    </FormControl>
  );
});
