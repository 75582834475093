import { createTheme } from "@mui/material";

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 568,
      md: 768,
      lg: 968,
      xl: 1080,
    },
  },
  palette: {
    primary: {
      main: "#F16335",
      dark: "#CF542A",
      light: "#FF8E61",
    },
    secondary: {
      main: "#EDEDED",
      light: "rgba(0, 0, 0, 0.1)",
      dark: "rgba(0, 0, 0, 0.85)",
    },
    text: {
      primary: "rgba(0, 0, 0, 0.85)",
      secondary: "#FFFFFF",
      disabled: "#555555",
    },
    success: {
      main: "#2e7d32",
      light: "#00A651",
    },
    error: {
      main: "#d32f2f",
      light: "#FF4D4F",
    },
  },
  spacing: [0, 10, 20, 30],
  shape: {
    borderRadius: 4,
  },
  typography: {
    fontFamily: "proxima-nova, sans-serif",
  },
});
