import axios from "axios";
import { getAuth } from "firebase/auth";

const { REACT_APP_API_URL } = process.env;

const getIdTokenRefreshed: (refresh?: boolean) => Promise<string> = async (refresh = true) => {
  const auth = getAuth();

  return new Promise(async (resolve) => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      unsubscribe();

      if (user) {
        const refreshedToken = await user.getIdToken(refresh);
        resolve(refreshedToken);
      }

      resolve("");
    });
  });
};

export const getData = async (apiPrefix: string) => {
  const authToken = await getIdTokenRefreshed();

  return axios
    .get(`${REACT_APP_API_URL}${apiPrefix}`, {
      headers: {
        firebaseToken: authToken,
      },
    })
    .then((response) => response.data)
    .catch((error) => error.response);
};

export const postData = async (apiPrefix: string, data: Record<string, unknown>) => {
  const authToken = await getIdTokenRefreshed();

  return axios
    .post(`${REACT_APP_API_URL}${apiPrefix}`, data, {
      headers: {
        firebaseToken: authToken,
      }
    })
    .then((response) => response.data)
    .catch((error) => error.response);
};

