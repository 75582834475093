import React, {memo, useEffect, useMemo} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "../../../store/hooks";
import { getBusiness } from "../../../store/modules/business/actions";

import { ReactComponent as PrivatelyOwned } from "../../../assets/icons/ico_hosts_dashboard.svg";
import { ReactComponent as Glamping } from "../../../assets/icons/ico_glamping_dashboard.svg";
import { ReactComponent as CampingSpots } from "../../../assets/icons/ico_listings_dashboard.svg";
import { ReactComponent as Accommodation } from "../../../assets/icons/ico_accommodation_dashboard.svg";
import { ReactComponent as AverageOrderValue } from "../../../assets/icons/ico_bookings_dashboard.svg";
import { ReactComponent as AverageLengthStay } from "../../../assets/icons/ico_nigths_dashboard.svg";
import { ReactComponent as Acquisition } from "../../../assets/icons/ico_acquisition_dashboard.svg";
import { ReactComponent as HostReferrals } from "../../../assets/icons/ico_customer_satisfaction_dashboard.svg";
import { ReactComponent as Reviews } from "../../../assets/icons/ico_reviews_dashboard.svg";
import { ReactComponent as AppDownloads } from "../../../assets/icons/ico_app_dashboard.svg";

import { CardsGrid, FiltersColumn, FiltersContainer, BusinessOverviewContainer } from "./components/styled";
import { ChartItem, DataItem, DataChartItem } from "./components/data";
import { LineChart } from "../../../components/charts/lineChart";
import { BarChart } from "../../../components/charts/barChart";
import { Select } from "../../../components/select/select";
import {getStatistics} from "../../../store/modules/dashboard/actions";
import {numberToLocale} from "../../dashboard/utils/number-to-locale";
import {targets} from "../../../utils/configs/targets";

const data = [
  {
    label: "8 Nov.",
    current: 2500,
    prev: 1000,
  },
  {
    label: "9 Nov.",
    current: 5000,
    prev: 6000,
  },
  {
    label: "10 Nov.",
    current: 4000,
    prev: 5500,
  },
  {
    label: "11 Nov.",
    current: 7400,
    prev: 8200,
  },
  {
    label: "12 Nov.",
    current: 4600,
    prev: 2400,
  },
  {
    label: "13 Nov.",
    current: 4500,
    prev: 7500,
  },
  {
    label: "14  Nov.",
    current: 10000,
    prev: 7500,
  },
];

const barData = [
  {
    name: "AUS",
    label2: 22,
    label1: 28,
  },
  {
    name: "DE",
    label2: 21,
    label1: 25,
  },
  {
    name: "DK",
    label2: 25,
    label1: 35,
  },
  {
    name: "FR",
    label2: 25,
    label1: 28,
  },
  {
    name: "IT",
    label2: 25,
    label1: 29,
  },
  {
    name: "NL",
    label2: 25,
    label1: 29,
  },
  {
    name: "NO",
    label2: 25,
    label1: 34,
  },
  {
    name: "PT",
    label2: 25,
    label1: 30,
  },
];


const barDataShort = [
  {
    name: "AUS",
    label2: 22,
    label1: 28,
  },
  {
    name: "DE",
    label2: 21,
    label1: 25,
  },
];

export const BusinessOverview = memo(() => {

  const { statistics, isPending } = useSelector((state) => state.business);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBusiness());
  }, [dispatch]);

  const publicListingsHeader = useMemo(() => {
    console.log("statics",statistics);
  }, [statistics]);

  const pending = useMemo(() => {
    console.log("pending",isPending);
  }, [isPending]);

  const barLabels = [
    t("BUSINESS.LAST_WEEK"),
    t("BUSINESS.PREVIOUS_PERIOD"),
  ];

  return (
    <BusinessOverviewContainer>

      <FiltersContainer>
        <FiltersColumn>
          <Select />
          <Select />
        </FiltersColumn>
        <FiltersColumn>
          <Select />
          <Select />
        </FiltersColumn>
      </FiltersContainer>

      <CardsGrid container spacing={2}>
        <ChartItem headerValue={"€13.000"} headerLabel={t("BUSINESS.GROSS_MERCHANDISE_VALUE")} change={0.2}>
          <LineChart data={data} />
        </ChartItem>
        <ChartItem headerValue={"€650"} headerLabel={t("BUSINESS.REVENUE")} change={0.2}>
          <LineChart data={data} />
        </ChartItem>
      </CardsGrid>

      <CardsGrid container spacing={2}>
        <ChartItem headerValue={"130"} headerLabel={t("BUSINESS.BOOKINGS")} change={-0.2}>
          <LineChart data={data} />
        </ChartItem>
        <DataItem header={`4 ${t("BUSINESS.NIGHTS")}`} change={-0.2} subheader={t("BUSINESS.AVERAGE_LENGTH_STAY")} icon={AverageLengthStay} />
        <DataItem header={"€45,00"} change={-0.2} subheader={t("BUSINESS.AVERAGE_ORDER_VALUE")} icon={AverageOrderValue} />
      </CardsGrid>

      <CardsGrid container spacing={2}>
        <DataChartItem header={"50%"} change={0.2} subheader={t("BUSINESS.RETENTION_RATE")} />
        <DataChartItem header={"50%"} change={-0.2} subheader={t("BUSINESS.HOST_ACTIVE_RATE")} />
        <ChartItem headerValue={null} headerLabel={t("BUSINESS.BOOKINGS_COUNTRY")} change={null}>
          <BarChart data={barData} labels={barLabels} />
        </ChartItem>
      </CardsGrid>

      <CardsGrid container spacing={2}>
        <ChartItem headerValue={"553"} headerLabel={t("BUSINESS.PUBLIC_LISTINGS")} change={0.2}>
          <LineChart data={data} />
        </ChartItem>
        <ChartItem headerValue={"384"} headerLabel={t("BUSINESS.HOSTS")} change={0.2}>
          <LineChart data={data} />
        </ChartItem>
      </CardsGrid>

      <CardsGrid container spacing={2}>
        <DataItem header={"30%"} change={-0.2} subheader={t("BUSINESS.PRIVATELY_OWNED")} icon={PrivatelyOwned} />
        <DataItem header={"40%"} change={-0.2} subheader={t("BUSINESS.GLAMPING")} icon={Glamping} />
        <DataItem header={"40%"} change={-0.1} subheader={t("BUSINESS.CAMPING_SPOTS")} icon={CampingSpots} />
        <DataItem header={"60%"} change={-0.2} subheader={t("BUSINESS.PROVIDED_ACCOMMODATION")} icon={Accommodation} />
      </CardsGrid>

      <CardsGrid container spacing={2}>
        <ChartItem headerValue={"837"} headerLabel={t("BUSINESS.VISITORS")} change={-0.2}>
          <LineChart data={data} />
        </ChartItem>
        <ChartItem headerValue={null} headerLabel={t("BUSINESS.VISITORS_COUNTRY")} change={null}>
          <BarChart data={barData} labels={barLabels} />
        </ChartItem>
      </CardsGrid>

      <CardsGrid container spacing={2}>
        <ChartItem headerValue={"553"} headerLabel={t("BUSINESS.VISITORS")} change={0.2}>
          <LineChart data={data} />
        </ChartItem>
        <ChartItem headerValue={null} headerLabel={t("BUSINESS.ORGANIC_PAID_TRAFFIC")} change={null} sizeChart={3}>
          <BarChart data={barDataShort} labels={barLabels} rightMargin={0} />
        </ChartItem>
        <DataItem header={"€65,00"} change={0.2} subheader={t("BUSINESS.ACQUISITION_COSTS")} icon={Acquisition} />
      </CardsGrid>

      <CardsGrid container spacing={2}>
        <DataItem header={"250"} change={-0.2} subheader={t("BUSINESS.REVIEWS")} icon={Reviews} />
        <DataItem header={"40"} change={-0.2} subheader={t("BUSINESS.HOST_REFERRALS")} icon={HostReferrals} />
        <DataItem header={"4.500"} change={-0.1} subheader={t("BUSINESS.APP_DOWNLOADS")} icon={AppDownloads} />
      </CardsGrid>

    </BusinessOverviewContainer>
  );
});
