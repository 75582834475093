import React, { memo, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as CampanyonLogo } from "../../assets/images/logo-orange.svg";
import { LoginButton, LogoWrapper, PendingSpinner } from "./components/styled";
import { Grid } from "../../components/grid/grid";

import { resetSignupInit } from "../../store/modules/auth/slice";
import { signInWithGoogle } from "../../store/modules/auth/actions";
import { useDispatch, useSelector } from "../../store/hooks";
import { Snackbar } from "../../components/snackbar/snackbar";

export const Signup = memo(() => {
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>();

  const { isPending, error } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (error) {
      setShowAlert(true);
      setAlertMessage(error);
    }
  }, [error]);

  useEffect(() => {
    dispatch(resetSignupInit());
  }, [dispatch]);

  const signInWithGoogleHandler = useCallback(() => {
    dispatch(signInWithGoogle());
  }, [dispatch]);

  return (
    <Grid container direction="column" alignItems="center" justifyContent="center" minHeight="100vh">
      <LogoWrapper>
        <CampanyonLogo width={200} />
      </LogoWrapper>
      <LoginButton
        loading={isPending}
        loadingIndicator={<PendingSpinner />}
        aria-label="login"
        size="large"
        variant="contained"
        onClick={signInWithGoogleHandler}
      >
        {t("SIGN_UP.LOGIN_WITH_GOOGLE")}
      </LoginButton>
      <Snackbar message={alertMessage} setShowAlert={setShowAlert} showAlert={showAlert} />
    </Grid>
  );
});
