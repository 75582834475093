import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { getBusiness } from "./actions";

export type BusinessData = {
  bookings: number;
  hosts: number;
  listings: number;
  satisfaction: number;
};

export type BusinessState = {
  isPending: boolean;
  statistics: BusinessData | null;
};

const initialState: BusinessState = {
  isPending: true,
  statistics: null,
};

const businessSlice = createSlice({
  name: "business",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getBusiness.pending, (state) => {
      state.isPending = true;
    });
    builder.addCase(getBusiness.rejected, (state) => {
      state.isPending = false;
    });
    builder.addCase(getBusiness.fulfilled, (state, action: PayloadAction<BusinessData>) => {
      state.statistics = action.payload;
      state.isPending = false
    });
  },
});

export default businessSlice;
