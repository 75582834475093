import React, { memo } from "react";
import { Provider } from "react-redux";
import { initializeApp } from "firebase/app";
import { PersistGate } from "redux-persist/integration/react";

import { Router } from "../router/router";
import { ThemeProvider } from "../theme/theme";

import { store, persistedStore } from "../../store/store";
import { firebaseConfig } from "../../utils/configs/firebase-config";
import { initializeIntl } from "../../utils/intl/intl";

initializeApp(firebaseConfig);
initializeIntl();

export const App = memo(() => {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistedStore}>
        <ThemeProvider>
          <Router />
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
});
