import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {getSalesCancellations} from "./actions";

export type SalesCancellations = {
  cancellations: any;
};

export type SalesCancellationsState = {
  isPending: boolean;
  salesCancellations: SalesCancellations[];
};

const initialState: SalesCancellationsState = {
  isPending: true,
  salesCancellations: [],
};

const salesCancellationsSlice = createSlice({
  name: "salesCancellations",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSalesCancellations.pending, (state) => {
      state.isPending = true;
    });
    builder.addCase(getSalesCancellations.rejected, (state) => {
      state.isPending = false;
    });
    builder.addCase(getSalesCancellations.fulfilled, (state, action: PayloadAction<SalesCancellations[]>) => {
      state.salesCancellations = action.payload;
      state.isPending = false;
    });
  },
});

export default salesCancellationsSlice;
