// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { memo, useEffect, useMemo, useState } from "react";
import moment from "moment";

import {
  SalesCancellationsContainer,
  PendingSpinnerContainer,
  PendingSpinner,
  SalesListContainer,
  DataGridContainer,
  GridToolbarContainerExport,
  GridToolbarExport,
} from "./components/styled";
import { useDispatch, useSelector } from "../../../store/hooks";
import { getSalesCancellations } from "../../../store/modules/sales-cancellations/actions";

export const SalesCancellations = memo(() => {
  const [initialized, setInitialized] = useState<boolean>(false);

  const { salesCancellations, isPending } = useSelector((state) => state.salesCancellations);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSalesCancellations());
    setInitialized(true);
  }, [dispatch]);

  const cols = [
    { field: "id", headerName: "ID"  },
    { field: "status", headerName: "Status" },
    { field: "reason", headerName: "Reason", flex: 1 },
  ];

  const status = [
    "Requested",
    "Confirmed",
    "PendingPayment",
    "Paid",
    "Completed",
    "Declined",
    "Cancelled",
    "Payout",
  ];

  const sortedSalesCancellations = useMemo(() => {
    if (salesCancellations.length) {
      return salesCancellations.slice().sort((a, b) => moment(b.created_at).diff(a.created_at));
    }

    return [];
  }, [salesCancellations]);

  const rows = useMemo(() => {
    if (sortedSalesCancellations.length) {
      return sortedSalesCancellations.map((item) => {
        return {
          id: item.id,
          createdAt: item.created_at.substring(0, 10),
          status: status[item.status],
          reason: item.cancel_reason || item.decline_reason || "",
        };
      });
    }

    return [];
  }, [sortedSalesCancellations, status]);

  const CustomToolbar = () => {
    return (
      <GridToolbarContainerExport>
        <GridToolbarExport printOptions={{}}/>
      </GridToolbarContainerExport>
    );
  };

  return (
    <SalesCancellationsContainer>
      {isPending || !initialized ? (
        <PendingSpinnerContainer>
          <PendingSpinner />
        </PendingSpinnerContainer>
      ) : (
        <SalesListContainer>
          <DataGridContainer autoHeight rows={rows} columns={cols} components={{ Toolbar: CustomToolbar }}  />
        </SalesListContainer>
      )}
    </SalesCancellationsContainer>
  );
});
