import React, { memo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import MuiSnackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";

export type SnackbarProps = {
  showAlert: boolean;
  duration?: number;
  message: string | undefined | null;
  setShowAlert: (value: boolean) => void;
  type?: "success" | "info" | "warning" | "error";
};

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const Snackbar = memo(
  ({ showAlert = false, type = "error", duration = 6000, message, setShowAlert }: SnackbarProps) => {
    const { t } = useTranslation();

    const handleClose = useCallback(
      (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === "clickaway") {
          return;
        }

        setShowAlert(false);
      },
      [setShowAlert],
    );

    return (
      <MuiSnackbar open={showAlert} autoHideDuration={duration} onClose={handleClose}>
        <Alert onClose={handleClose} severity={type} sx={{ width: "100%" }}>
          {message ? t(message) : t("MESSAGES.SOMETHING_WENT_WRONG")}
        </Alert>
      </MuiSnackbar>
    );
  },
);
