import React, { memo, ReactElement } from "react";
import { ThemeProvider as MaterialThemeProvider } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";

import { theme } from "../../utils/theme/theme";

type ThemeProviderProps = {
  children: ReactElement;
};

export const ThemeProvider = memo(({ children }: ThemeProviderProps) => {
  return (
    <MaterialThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </MaterialThemeProvider>
  );
});
