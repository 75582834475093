import React, { memo, useMemo } from "react";
import { CartesianGrid, Line, LineChart as Chart, Tooltip, XAxis, YAxis, ResponsiveContainer } from "recharts";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "../../utils/hooks/use-media-query";

const XAxisTick = ({ x, y, payload, index }: any) => {
  const { isMobile } = useMediaQuery();

  const dx = useMemo(() => {
    if (isMobile) {
      return -4;
    }

    return index === 0 ? 0 : -16;
  }, [isMobile, index]);

  return (
    <g>
      <text x={x} y={y} fill="rgba(0, 0, 0, 0.45)" dx={dx} dy={8} fontSize={10} letterSpacing={0}>
        {payload.value}
      </text>
    </g>
  );
};

const YAxisTick = ({ x, y, payload }: any) => (
  <g>
    <text x={x} y={y} fill="rgba(0, 0, 0, 0.45)" fontSize={10} letterSpacing={0} dx={-4} dy={4}>
      {payload.value}
    </text>
  </g>
);

type LineChartProps = {
  data: { label: string; prev: number; current: number }[];
  width?: number;
  height?: number;
  type?: "number" | "percentage";
};

export const LineChart = memo(({ data, width = 450, height = 200, type = "number" }: LineChartProps) => {
  const { isTablet, isMobile } = useMediaQuery();
  const { t } = useTranslation();
  const theme = useTheme();

  const calculatedHeight = useMemo(() => {
    if (isTablet) {
      return height * 1.2;
    }

    if (isMobile) {
      return height * 1.4;
    }

    return height;
  }, [height, isTablet, isMobile]);

  const currentLabel = useMemo(() => {
    return String(t("SALES.CURRENT"));
  }, [t]);

  const prevLabel = useMemo(() => {
    return String(t("SALES.PREVIOUS"));
  }, [t]);

  const parsedData = useMemo(() => {
    return data.map((v) => ({
      name: v.label,
      [currentLabel]: v.current,
      [prevLabel]: v.prev,
    }));
  }, [data, currentLabel, prevLabel]);

  return (
    <ResponsiveContainer width="100%" height={calculatedHeight}>
      <Chart
        width={width}
        height={calculatedHeight}
        data={parsedData}
        margin={{ top: 8, left: 4, right: -28, bottom: 0 }}
      >
        <CartesianGrid vertical={false} stroke={theme.palette.secondary.main} />
        <XAxis
          interval={0}
          dataKey="name"
          tickLine={false}
          axisLine={false}
          padding={{ right: 20 }}
          tick={<XAxisTick />}
        />
        <YAxis interval={0} tickCount={5} orientation="right" tickLine={false} axisLine={false} tick={<YAxisTick />} />
        <Tooltip
          separator=": "
          labelStyle={{ color: theme.palette.text.primary, fontSize: "12px", paddingBottom: 8 }}
          itemStyle={{ color: theme.palette.text.primary, fontSize: "12px", padding: 0 }}
          contentStyle={{ borderColor: theme.palette.secondary.main, borderRadius: 4 }}
          cursor={{ stroke: theme.palette.secondary.main }}
        />
        <Line type="linear" dataKey={currentLabel} stroke={theme.palette.primary.main} dot={false} />
        <Line type="linear" dataKey={prevLabel} stroke={theme.palette.primary.main} dot={false} strokeDasharray="8 4" />
      </Chart>
    </ResponsiveContainer>
  );
});
