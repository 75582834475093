import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { getHostsByDay, getListingsByDay, getListingsAvailability, getListingsPublic } from "./actions";

export type SalesStatistics = {
  change_percentage: number
  timeseries: []
  timeseries_compare: []
  total: number
  total_compare: number
};

export type SalesOverviewState = {
  isPending: boolean;
  hostsByDay: SalesStatistics | null;
  listingsByDay: SalesStatistics | null;
  listingsAvailability: SalesStatistics | null;
  listingsPublic: SalesStatistics | null;
};

const initialState: SalesOverviewState = {
  isPending: true,
  hostsByDay: null,
  listingsByDay: null,
  listingsAvailability: null,
  listingsPublic: null
};

const salesHostsByDaySlice = createSlice({
  name: "hostsByDay",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getHostsByDay.pending, (state) => {
      state.isPending = true;
    });
    builder.addCase(getHostsByDay.rejected, (state) => {
      state.isPending = false;
    });
    builder.addCase(getHostsByDay.fulfilled, (state, action: PayloadAction<SalesStatistics>) => {
      state.hostsByDay = action.payload;
      state.isPending = false;
    });
  },
});

const salesListingsByDaySlice = createSlice({
  name: "listingsByDay",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getListingsByDay.pending, (state) => {
      state.isPending = true;
    });
    builder.addCase(getListingsByDay.rejected, (state) => {
      state.isPending = false;
    });
    builder.addCase(getListingsByDay.fulfilled, (state, action: PayloadAction<SalesStatistics>) => {
      state.listingsByDay = action.payload;
      state.isPending = false;
    });
  },
});

const salesListingsByAvailability = createSlice({
  name: "listingsAvailability",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getListingsAvailability.pending, (state) => {
      state.isPending = true;
    });
    builder.addCase(getListingsAvailability.rejected, (state) => {
      state.isPending = false;
    });
    builder.addCase(getListingsAvailability.fulfilled, (state, action: PayloadAction<SalesStatistics>) => {
      state.listingsAvailability = action.payload;
      state.isPending = false;
    });
  },
});

const salesListingsPublic = createSlice({
  name: "listingsPublic",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getListingsPublic.pending, (state) => {
      state.isPending = true;
    });
    builder.addCase(getListingsPublic.rejected, (state) => {
      state.isPending = false;
    });
    builder.addCase(getListingsPublic.fulfilled, (state, action: PayloadAction<SalesStatistics>) => {
      state.listingsPublic = action.payload;
      state.isPending = false;
    });
  },
});

export { salesHostsByDaySlice, salesListingsByDaySlice, salesListingsByAvailability, salesListingsPublic };
