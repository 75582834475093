import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

export const BusinessOverviewContainer = styled("div")({
  paddingBottom: 20,
});

export const CardsGrid = styled(Grid)({
  marginTop: 0,
});

export const GridItem = styled(Grid)({});

export const PendingSpinner = styled(CircularProgress)({
  height: "27px !important",
  width: "27px !important",
  marginBottom: "10px",
  marginTop: "10px",
});

export const ItemLight = styled(Paper)(({ theme }) => ({
  borderColor: theme.palette.secondary.main,
  borderWidth: "1px",
  borderStyle: "solid",
  boxShadow: "none",
}));

export const DataCardWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  height: "100%",
});

export const DataCardHeader = styled(Typography)(({ theme }) => ({
  fontSize: "30px",
  fontWeight: "500",
  letterSpacing: 0,
  lineHeight: "37px",
  marginTop: "10px",
  color: theme.palette.primary.main,
}));

export const DataCardSubheader = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  fontWeight: "500",
  letterSpacing: 0,
  lineHeight: "17px",
  marginTop: "8px",
  color: theme.palette.text.primary,
}));

export const DataCardChange = styled(Typography)({
  display: "flex",
  alignItems: "center",
  fontSize: "12px",
  letterSpacing: 0,
  lineHeight: "15px",
  marginTop: "10px",
});

export const ChartItemLight = styled(Paper)(({ theme }) => ({
  borderColor: theme.palette.secondary.main,
  borderWidth: "1px",
  borderStyle: "solid",
  boxShadow: "none",
  borderRadius: 0,
  padding: "24px 32px",

  [theme.breakpoints.down("md")]: {
    padding: "16px 16px 8px 16px",
  },
}));

export const ChartItemHeaderValue = styled(Typography)({
  fontSize: "30px",
  fontWeight: "500",
  letterSpacing: 0,
  lineHeight: "37px",
});

export const ChartItemHeaderLabel = styled(Typography)({
  marginLeft: "8px",
  marginBottom: "6px",
  fontSize: "14px",
  letterSpacing: 0,
  lineHeight: "15px",
});

export const ChartItemDataChange = styled(Typography)({
  display: "flex",
  alignItems: "center",
  fontSize: "12px",
  letterSpacing: 0,
  lineHeight: "15px",
  marginBottom: "6px",
  marginLeft: "14px",
});

export const ChartHeaderContainer = styled("div")({
  alignItems: "flex-end",
  display: "flex",
  marginBottom: "12px",
});

export const FiltersContainer = styled("div")({
  display: "flex",
  justifyContent: "space-between",
});

export const FiltersColumn = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  width: "50%",
  "&:first-of-type": {
    marginRight: 10,
  },
  "&:last-of-type": {
    marginLeft: 10,
  },
});
