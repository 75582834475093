import { externalLinks } from "../../../utils/configs/external-links";

export const externalLinksMap = [
  {
    url: externalLinks.feedbackForm,
    label: "DASHBOARD.FEEDBACK_FORM",
  },
  {
    url: externalLinks.vacationTracker,
    label: "DASHBOARD.VACATION_TRACKER",
  },
  {
    url: externalLinks.hubspot,
    label: "DASHBOARD.HUBSPOT",
  },
  {
    url: externalLinks.hotjar,
    label: "DASHBOARD.HOTJAR",
  },
  {
    url: externalLinks.sendgrid,
    label: "DASHBOARD.SENDGRID",
  },
  {
    url: externalLinks.campanyon,
    label: "DASHBOARD.CAMPANYON",
  },
  {
    url: externalLinks.campanyonDev,
    label: "DASHBOARD.CAMPANYON_TEST",
  },
  {
    url: externalLinks.bonfire,
    label: "DASHBOARD.BONFIRE",
  },
  {
    url: externalLinks.linkedIn,
    label: "DASHBOARD.LINKEDIN",
  },
];

export const salesLinksMap = [
  {
    url: null,
    route: "/sales",
    label: "DASHBOARD.OVERVIEW",
  },
  {
    url: null,
    route: "/sales/listings",
    label: "DASHBOARD.LISTINGS",
  },
  {
    url: null,
    route: "/sales/bookings",
    label: "DASHBOARD.BOOKINGS",
  },
  {
    url: null,
    route: null,
    label: "DASHBOARD.ONBOARD_HOST",
    disabled: true,
  },
  {
    url: externalLinks.uniqueLocations,
    route: null,
    label: "DASHBOARD.UNIQUE_LOCATIONS",
  },
  {
    url: externalLinks.scrapeRequest,
    route: null,
    label: "DASHBOARD.SCRAPE_REQUEST",
  },
  {
    url: externalLinks.zendesk,
    route: null,
    label: "DASHBOARD.ZENDESK",
  },
  {
    url: externalLinks.salesHandbook,
    route: null,
    label: "DASHBOARD.SALES_HANDBOOK",
  },
  {
    url: externalLinks.scoutOnboardingKit,
    route: null,
    label: "DASHBOARD.SCOUT_ONBOARDING_KIT",
  },
  {
    url: externalLinks.salesDeck,
    route: null,
    label: "DASHBOARD.SALES_DECK",
  },
];

export const businessLinksMap = [
  {
    url: null,
    route: "/business",
    label: "DASHBOARD.OVERVIEW",
    disabled: false
  },

  {
    url: externalLinks.brandBook,
    route: null,
    label: "DASHBOARD.BRAND_BOOK",
  },
  {
    url: externalLinks.strategy2022,
    route: null,
    label: "DASHBOARD.STRATEGY_2022",
  },
];
