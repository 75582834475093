import { styled } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import MuiToolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Sidebar from "@mui/material/Drawer";

export { Sidebar };

export const AppBar = styled(MuiAppBar)(({ theme }) => ({
  borderColor: theme.palette.secondary.light,
  backgroundColor: "transparent",
  borderWidth: "1px",
  borderStyle: "solid",
  boxShadow: "none",
  borderLeft: "none",
  borderRight: "none",
  borderTop: "none",
}));

export const Toolbar = styled(MuiToolbar)({
  justifyContent: "space-between",
  minHeight: "70px",
});

export const ToolbarFlexWrapper = styled("div")(({ theme }) => ({
  alignItems: "center",
  display: "flex",
  svg: {
    cursor: "pointer",
  },
  "a, button": {
    minWidth: "unset",
    margin: "0 20px",
  },
  "button:last-of-type": {
    margin: "0 0 0 20px",
  },
  [theme.breakpoints.down("lg")]: {
    "a, button": {
      minWidth: "unset",
      marginRight: 40,
      paddingRight: 0,
      paddingLeft: 0,
    },
    "button:last-of-type": {
      paddingRight: 0,
      paddingLeft: 0,
      margin: "0 40px 0 0",
    },
  },
}));

export const HeaderContainer = styled(Container)({
  padding: "0 20px !important",
});

export const MobileMenuWrapper = styled("div")({
  marginRight: "4px",
});

export const MobileCrossWrapper = styled("div")({
  display: "flex",
  width: "100%",
  justifyContent: "flex-end",
  marginBottom: "20px",
  paddingRight: "8px",
});

export const MobileLinksContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  width: "280px",
  padding: "24px 16px",
  "a, button": {
    minWidth: "unset !important",
    textTransform: "none !important",
    marginBottom: "16px !important",
  },
});

export const TabletHeaderContainer = styled("div")({
  display: "flex",
  alignItems: "center",
});
