import React, {memo, useMemo} from "react";
import { useTheme } from "@mui/material/styles";
import { BarChart as Chart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";

const XAxisTick = ({ x, y, payload }: any) => {
  return (
    <g>
      <text x={x} y={y} fill="rgba(0, 0, 0, 0.45)" dx={-8} dy={8} fontSize={10} letterSpacing={0}>
        {payload.value}
      </text>
    </g>
  );
};

const YAxisTick = ({ x, y, payload }: any) => (
  <g>
    <text x={x} y={y} fill="rgba(0, 0, 0, 0.45)" fontSize={10} letterSpacing={0} dx={-4} dy={4}>
      {payload.value}
    </text>
  </g>
);

type BarChartProps = {
    data: { name: string; label1: number; label2: number }[];
    labels: string[];
    colors?: string[];
    width?: number;
    height?: number;
    rightMargin?: number;
};

const BarColors = ["#F16335", "#F4ADB1"];

export const BarChart = memo(({ data, labels, colors = BarColors, width = 500, height = 215, rightMargin = 80 }: BarChartProps)  => {
  const theme = useTheme();

  const LegendText = ( value: any ) => (
    <span style={{ color: theme.palette.text.primary, padding: "5px 20px 0 5px" }}>{value}</span>
  );

  return (
    <ResponsiveContainer width="100%" height={height}>
      <Chart
        width={width}
        height={height}
        data={data}
      >
        <CartesianGrid vertical={false} stroke={theme.palette.secondary.main} />
        <XAxis dataKey="name" axisLine={false} padding={{ right: rightMargin }} tickLine={false} tick={<XAxisTick />} />
        <YAxis orientation="right" axisLine={false} tickLine={false} interval={0} tickCount={5} tick={<YAxisTick />} />
        <Tooltip
          separator=": "
          labelStyle={{ color: theme.palette.text.primary, fontSize: "12px", paddingBottom: 8 }}
          itemStyle={{ color: theme.palette.text.primary, fontSize: "12px", padding: 0 }}
          contentStyle={{ borderColor: theme.palette.secondary.main, borderRadius: 4 }}
          cursor={{ stroke: theme.palette.secondary.main }}
        />
        <Legend iconType="circle" iconSize={8} align="left" wrapperStyle={{ fontSize: "12px"}} formatter={LegendText}/>
        {
          labels?.map((item, idx) => <Bar key={idx} dataKey={`label${idx + 1}`} name={item} fill={colors[idx]} />)
        }
      </Chart>
    </ResponsiveContainer>
  );
});
