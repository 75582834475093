export const locations = {
  salesOverview: "/sales",
  salesListings: "/sales/listings",
  salesBookings: "/sales/bookings",
  cancellations: "/sales/cancellations",
  salesOnboardHost: "/sales/onboard",
};

export const salesLocations = [
  locations.salesOverview,
  locations.salesListings,
  locations.salesBookings,
  locations.cancellations,
  locations.salesOnboardHost,
];
