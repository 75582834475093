import React, { memo, useMemo, ReactElement } from "react";
import { useLocation, Navigate } from "react-router-dom";

import { useSelector } from "../../store/hooks";

export enum AccessEnum {
  authenticated = "authenticated",
  notAuthenticated = "notAuthenticated",
}

type AuthWrapperProps = {
  children: ReactElement;
  redirectPath: string;
  access: AccessEnum.authenticated | AccessEnum.notAuthenticated;
};

export const AuthWrapper = memo(({ children, redirectPath, access }: AuthWrapperProps) => {
  const { authToken } = useSelector((state) => state.auth);
  const location = useLocation();

  const showChildren = useMemo(() => {
    return access === AccessEnum.authenticated ? authToken !== null : !authToken;
  }, [access, authToken]);

  return showChildren ? children : <Navigate to={redirectPath} replace state={{ path: location.pathname }} />;
});
