import React, { memo } from "react";
import { Theme } from "@mui/material/styles";
import { NavLink } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Container from "@mui/material/Container";
import MuiTabs from "@mui/material/Tabs";
import MuiTab from "@mui/material/Tab";
import { SxProps } from "@mui/system";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

type LinkButtonBaseProps = {
  label: string;
  href: string;
  active: boolean;
  disabled?: boolean;
};

export const BusinessContainer = styled(Container)(({ theme }) => ({
  padding: "0 20px !important",
  [theme.breakpoints.up("lg")]: {
    marginTop: 64,
  },
  [theme.breakpoints.between("md", "lg")]: {
    marginTop: 40,
  },
  [theme.breakpoints.down("md")]: {
    marginTop: 34,
  },
}));

export const Tabs = styled(MuiTabs)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.secondary.light}`,
  marginBottom: 56,
  [theme.breakpoints.down(380)]: {
    ".MuiTabs-flexContainer": {
      justifyContent: "space-between",
    },
  },
}));

const styles: SxProps<Theme> = (theme) => ({
  color: theme.palette.text.primary,
  fontSize: "14px",
  letterSpacing: 0,
  lineHeight: "17px",
  minWidth: "unset",
  padding: "10px 2px",
  marginRight: "36px",
  textTransform: "none",
  opacity: "1",
  span: {
    display: "none",
  },
  "&.currentRoute": {
    color: theme.palette.text.primary,
    fontWeight: 600,
    letterSpacing: 0,
  },
  "&:hover": {
    backgroundColor: "transparent",
  },
  "&:first-of-type": {
    margin: "0 36px 0 0",
    paddingRight: "2px",
    paddingLeft: 0,
  },
  [theme.breakpoints.down("md")]: {
    margin: "0 24px 0 0 !important",
  },
  [theme.breakpoints.down(380)]: {
    margin: "0 !important",
  },
});

export const LinkTab = memo(({ label, href, active, disabled }: LinkButtonBaseProps) => (
  <MuiTab
    to={href}
    sx={styles}
    label={label}
    disabled={disabled}
    component={NavLink}
    className={active ? "currentRoute" : ""}
  />
));

export const BusinessHeaderContainer = styled("div")({
  justifyContent: "space-between",
  marginBottom: "16px",
  alignItems: "center",
  display: "flex",
});

export const BusinessHeader = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: "22px",
  fontWeight: "500",
  letterSpacing: 0,
  lineHeight: "27px",
}));

export const BusinessLinksContainer = styled("div")({
  display: "flex",
});

export const BusinessLinksContainerTablet = styled("div")({
  flexDirection: "column",
  display: "flex",
});

export const BusinessLinksRow = styled("div")({
  justifyContent: "flex-end",
  display: "flex",
});

export const ExternalLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: "14px",
  letterSpacing: "0.12px",
  lineHeight: "17px",
  textDecoration: "none",
  marginRight: "24px",
  "&:hover": {
    color: theme.palette.primary.dark,
  },
  "&:last-of-type": {
    marginRight: "0",
  },
  [theme.breakpoints.down("lg")]: {
    padding: "6px 8px",
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "20px",
    letterSpacing: 0,
    marginRight: 0,
  },
}));
