import { styled } from "@mui/material/styles";
import MuiGrid from "@mui/material/Grid";

type CustomGridProps = {
  minHeight?: number | string;
};

export const Grid = styled(MuiGrid)(({ minHeight }: CustomGridProps) => ({
  minHeight,
}));
